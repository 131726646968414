import React, { Component } from "react";
import "../Reports/Report.css";
import { getAnswer, addComment, setAddClass } from "../../Action";
import { connect } from "react-redux";
import { Row, Col, Button, Stack, Form } from "react-bootstrap";
import * as constants from "../../Config/Constant";
import Swal from "sweetalert2";
import StarRating from "./StarRating";
import { getBackEndConstant } from "../../Config/Constant";
import moment from "moment";
import Select from "react-select";
import DatePicker from "react-datepicker";
import Alert from 'react-bootstrap/Alert';
import "react-datepicker/dist/react-datepicker.css";
import "bootstrap/dist/css/bootstrap.min.css";
const Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  showCloseButton: true,
  customClass: {
    popup: "colored-toast",
    icon: "white-toast-icon",
  },
  timer: 3000,
  onOpen: (toast) => {
    toast.addEventListener("mouseenter", Swal.stopTimer);
    toast.addEventListener("mouseleave", Swal.resumeTimer);
  },
});

class PmsComments extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      answerList: [],
      comment: {},
      leadrating:"",
      managerrating : "",
      managercomment : "",
      synergyleadrating : "",
      leadcomment : "",
      synergyleadcomment : "",
      isCommentSubmitted: false,
      filter_start_date:"",
      filter_manager_start_date : "",
      filter_synergy_lead_start_date : "",
      chapterComment: {},
      performanceDateYear: this.props.performanceDateYear,
      performanceBatch: this.props.performanceBatch,
    };
  }
  /**
   * Initial function call
   */
  componentDidMount() {
    this.getAnswer();
  }

  // /* toast message function  */
  ToastAlert(icon, title) {
    return Toast.fire({
      target: document.getElementById("form-modal"),
      icon: `${icon}`,
      title: `${title}`,
    });
  }

  getAnswer = () => {
    const {
      actionProperty: { answerId },
      pageFrom,
    } = this.props;

    this.setState({ loading: true });
    let dataObject = { 
      id: answerId, 
      pageFrom,
      performanceDateYear: this.state.performanceDateYear,
      performanceBatch: this.state.performanceBatch, 
    };
    let payload = {};
    payload["data"] = dataObject;
    this.props.getAnswer(payload).then(
      (_response) => {
        let answerArray = {}; let chapterRatingComment = {};
        this.props.pmsDetails.getAnswer.forEach(element => {
          answerArray[element.questionId] = (element.comments.length > 0 ? element.comments[0] : null);
          chapterRatingComment[element.questionId] = (element.chapterComment.length > 0 ? element.chapterComment[0] : null);
        })
          const isCommented =
          this.props.pmsDetails.getAnswer &&
          this.props.pmsDetails.getAnswer[0]?.is_comments;
          const leadcomment =
          this.props.pmsDetails.getAnswer &&
          this.props.pmsDetails.getAnswer[0]?.leadoverallcomment;
          const leaddate =
          this.props.pmsDetails.getAnswer &&
          this.props.pmsDetails.getAnswer[0]?.leaddate !== null
          ? this.props.pmsDetails.getAnswer[0]?.leaddate
          : null;          
          const leadrating =
          this.props.pmsDetails.getAnswer &&
          this.props.pmsDetails.getAnswer[0]?.leadrating !== null
          ? this.props.pmsDetails.getAnswer[0]?.leadrating
          : null;
          const managerrating =
          this.props.pmsDetails.getAnswer &&
          this.props.pmsDetails.getAnswer[0]?.managerrating !== null
          ? this.props.pmsDetails.getAnswer[0]?.managerrating
          : null;
          const synergyleadrating =
          this.props.pmsDetails.getAnswer &&
          this.props.pmsDetails.getAnswer[0]?.synergyleadrating !== null
          ? this.props.pmsDetails.getAnswer[0]?.synergyleadrating
          : null;
          const managerdate =
          this.props.pmsDetails.getAnswer &&
          this.props.pmsDetails.getAnswer[0]?.managerdate !== null
          ? this.props.pmsDetails.getAnswer[0]?.managerdate
          : null;
          const synergyleaddate =
          this.props.pmsDetails.getAnswer &&
          this.props.pmsDetails.getAnswer[0]?.synergyleaddate !== null
          ? this.props.pmsDetails.getAnswer[0]?.synergyleaddate
          : null;
          const managercomment =
          this.props.pmsDetails.getAnswer &&
          this.props.pmsDetails.getAnswer[0]?.managercomments;
          const synergyleadcomment =
          this.props.pmsDetails.getAnswer &&
          this.props.pmsDetails.getAnswer[0]?.synergyleadcomments;
          const isComment =
          this.props.pmsDetails.getAnswer &&
          this.props.pmsDetails.getAnswer[0]?.is_comment;
        this.setState({
          loading: false,
          comment: answerArray,
          chapterComment: chapterRatingComment,
          answerList: this.props.pmsDetails.getAnswer,
          leadcomment,managercomment,synergyleadcomment,leaddate,managerdate,synergyleaddate,leadrating,managerrating,synergyleadrating,
          isCommented,isComment,
        });
      },
      (error) => {
        this.setState({
          loading: false,
        });
        this.ToastAlert("error", "Something went wrong");
      }
    );
  };

  handleCommentChange = (e, id) => {
    let comments = this.state.comment;
    if (e) {
      comments = { ...comments, [id]: e, };
    } else {
      // delete comments[id];
      comments = {
        ...comments,
        [id]: null,
      };
    }
    this.setState({
      ...this.state,
      comment: comments,
    });
  };
  handleRatingChange = (e,id) => {
    let comments = this.state.leadrating;
    if (e) {
      comments = e;
    }
    this.setState({
      ...this.state,
      leadrating: comments,
    });
  };
  handleManagerRatingChange = (e,id) => {
    let comments = this.state.managerrating;
    if (e) {
      comments = e;
    }
    this.setState({
      ...this.state,
      managerrating: comments,
    });
  };
  handleSynergyLeadRatingChange = (e,id) => {
    let comments = this.state.synergyleadrating;
    if (e) {
      comments = e;
    }
    this.setState({
      ...this.state,
      synergyleadrating: comments,
    });
  };
  handleLeadCommentChange = (e) => {
    let comments = "";
    if (e) {
      comments = e;
    }
    this.setState({
      ...this.state,
      leadcomment: comments,
    });
  };
  handleManagerCommentChange = (e) => {
    let comments = "";
    if (e) {
      comments = e;
    }
    this.setState({
      ...this.state,
      managercomment: comments,
    });
  };
  handleSynergyLeadCommentChange = (e) => {
    let comments = "";
    if (e) {
      comments = e;
    }
    this.setState({
      ...this.state,
      synergyleadcomment: comments,
    });
  };
  setStartDateForFilter = (e) => {
    let today = new Date(e);
    this.setState({
      filter_start_date: today,
    });
  };
  setManagerStartDateForFilter = (e) => {
    var today = new Date(e);
    this.setState({
      filter_manager_start_date: today,
    });
  };
  setSynergyLeadStartDateForFilter = (e) => {
    var today = new Date(e);
    this.setState({
      filter_synergy_lead_start_date: today,
    });
  };
  handleRatingComments = (e, id) => {
    let chapterComment = this.state.chapterComment;
    if (e) { chapterComment = { ...chapterComment, [id]: e, }; }
    else {
      chapterComment = { ...chapterComment, [id]: null, };
    }
    this.setState({
      ...this.state,
      chapterComment: chapterComment,
    });
  }
  handleManagerRatingComments = (e, id) => {
    let chapterComment = this.state.chapterComment;
    if (e) { chapterComment = { ...chapterComment, [id]: e, }; }
    else {
      chapterComment = { ...chapterComment, [id]: null, };
    }
    this.setState({
      ...this.state,
      chapterComment: chapterComment,
    });
  }
  handleSynergyLeadRatingComments = (e, id) => {
    let chapterComment = this.state.chapterComment;
    if (e) { chapterComment = { ...chapterComment, [id]: e, }; }
    else {
      chapterComment = { ...chapterComment, [id]: null, };
    }
    this.setState({
      ...this.state,
      chapterComment: chapterComment,
    });
  }

  /**
   * update the answer
   * @param {*} event 
   * @param {*} questionId 
   * @param {*} key 
   * @param {*} type 
   */
  handleInputChange = (event, questionId, key, type) => {
    let value = ""; // Initialize value variable
    if (type === 1) {
      // For type 1, extract value from event.target
      value = event.target.value;
    } else if (type === 3) {
      // For type 3, extract value from key
      const selectedOptions = { ...this.state.selectedOption };
      selectedOptions[questionId] = event;
      this.setState({ selectedOption: selectedOptions });
      value = event;
    } else {
      value = event;
      this.setState((rateState) => ({
        rating: {
          ...rateState.rating,
          [parseInt(questionId)]: event, // Use questionId as the key
        },
      }));
    }
    this.setState((prevState) => ({
      inputFields: {
        ...prevState.inputFields,
        [parseInt(questionId)]: value, // Use questionId as the key
      },
    }));

  };
  saveAnswer = (event) => {
    this.setState({
      isSaved: true,
    });
    let isPerform,
      dataObj = {},
      payload = {};
    dataObj.answer = this.state.inputFields;
    dataObj.userId = this.state.loginUserData.id;
    // dataObj.answerDate = moment().startOf("month").format("YYYY-MM-DD");
    payload["data"] = dataObj;
    if (
      Object.keys(this.state.inputFields).length ===
      this.props.pmsQuestionList.length
    ) {
      this.props.saveAnswer(payload).then((response) => {
        if (
          this.props.saveAnswerList.data &&
          this.props.saveAnswerList.data.is_saved === 1
        ) {
          isPerform = true;
        }
        this.setState({
          loading: false,
          isPerform: isPerform,
        });
        this.ToastAlert(
          "success",
          "Your Performance Status Saved Successfully"
        );
      });
    }
  };

   /** valaidation for lead comments */
    overAllCommentValidation = () => {
      const {
          leadcomment,
          leadrating,
      } = this.state;
      const fieldsToValidate = [
          leadcomment,
          leadrating,
          
      ];
      return fieldsToValidate.some(field => field == null || (typeof field === 'string' && field.trim() === ''));
    };

  getAnswerTypeElement = (item) => {
    const {
      questionType,
      questionId,
      answer,
      is_option,
      is_comments,
      comments,
      is_saved,
      isRatingComment, ratingComment, chapterComment
    } = item;
    let {
      userId,
      actionProperty: { reporting_to = null , manager_reporting_to = null , synergy_lead_reporting_to = null, practice_head = null  },
      pageFrom
    } = this.props;
    const { comment } = this.state;
    reporting_to = reporting_to === null ? this.props.pmsDetails.getAnswer &&
    this.props.pmsDetails.getAnswer[0]?.reporting_id: reporting_to;

    manager_reporting_to = manager_reporting_to === null ? this.props.pmsDetails.getAnswer &&
    this.props.pmsDetails.getAnswer[0]?.manager_reporting_id : manager_reporting_to;

    synergy_lead_reporting_to = synergy_lead_reporting_to === null ? this.props.pmsDetails.getAnswer &&
    this.props.pmsDetails.getAnswer[0]?.synergy_lead_reporting_id :synergy_lead_reporting_to;

    practice_head = practice_head === null ? this.props.pmsDetails.getAnswer &&
    this.props.pmsDetails.getAnswer[0]?.practice_head: practice_head;

    var primarycomment;
    if (reporting_to && reporting_to === manager_reporting_to) {
      primarycomment = "Manager";
      } else if (reporting_to && reporting_to === synergy_lead_reporting_to) {
          primarycomment = "Synergy Lead";
      } else if (reporting_to && reporting_to === practice_head) {
        primarycomment = "Director";
      } else {
          primarycomment = "Lead";
      }
     
    switch (questionType) {
      case 1:
        return (
    <div className="p-2">
      <div className="pb-2">
              <p className="mb-2" >Employee Answer</p>
        <div className="disabledInput">{answer}</div>

      </div>
            <div className="comments-iput-wrapper">
              {is_comments && typeof comments[0] === "string" ?  (
          <div className="d-flex">
            <span className="f-w-700 c-black">{primarycomment} Comment: </span>
            <span className="commentStyle ml-10">{comments[0]}</span>
          </div>
        ) : is_comments || reporting_to === userId || manager_reporting_to === userId || synergy_lead_reporting_to === userId ? (
          <>
            <div className="d-flex">
              <StarRating
                value={typeof comments[0] === "number" ? comments[0] : (comment[questionId] || 0)}
                starColor={"#F07F1E"}
                label={`${primarycomment} Rating`}
                isSelectable={!is_comments}
                handleCommentChange={(e, rating) => this.handleCommentChange(e, questionId)}
              />
            </div>
          </>
        ) : (
          <div className="d-flex"></div>
        )}
      </div>
    </div>
        );
      case 2:
        return (
          <>
            <div className="d-flex">
                <StarRating
                  value={answer ? answer : this.state.rating}
                  starColor={"#F07F1E"}
                  isSelectable={false}
                  className="mr-20"
                  label="Employee Rating"
                />
                {isRatingComment && ratingComment != null ? (
                  <>
                  <div className="textarea-review w-100 position-relative">
                  <p>Employee Answer</p>
                  <div className="textarea-container">
                    <textarea
                      defaultValue={ratingComment === null ? "" : ratingComment}
                      placeholder="Add Comment"
                      disabled={is_saved}
                      value={ratingComment}
                      label="Employee Comment"
                      className="w-100 custom-textarea"
                      onChange={(e) => this.handleCommentChange(e.target.value, questionId)}
                    />
                  </div>
                </div>
                  </>): (<><div className="d-none"></div></>)
                }
            </div>
            {(is_comments || reporting_to === userId || manager_reporting_to === userId || synergy_lead_reporting_to === userId)  && (
              <>
                  <div className="d-flex">
                    <StarRating
                      value={(comments[0] !== null ? comments[0] : comment[questionId]) || 0}
                      starColor={"#F07F1E"}
                      label={`${primarycomment} Rating`}
                      isSelectable={!is_comments}
                      handleCommentChange={(e, rating) =>
                        this.handleCommentChange(e, questionId)
                      }
                    />
                    {/* <div className="textarea-review w-100">
                      <p>{primarycomment} comment</p>
                      <textarea defaultValue={chapterComment == null ? "" : chapterComment}
                        placeholder="Add Comment"
                        className="w-100"
                        disabled={is_comments}
                        label={`${primarycomment} Rating`}
                        onChange={(e) => this.handleRatingComments(e.target.value, questionId)} />
                    </div> */}
                 </div>  
              </>
            )}
          </>
        );
      case 3:
        return (
          <div className="p-2">
            <Form>
              {is_option.map((type, index) => (
                <div key={`inline-radio-${index}`} className="mb-1">
                  <Form.Check
                    inline
                    label={type}
                    name="group1"
                    //index + 1 => in pms review(user submittin review) mcq option is take as 1,2,3 instead of 0,1,1
                    checked={index === answer}
                    // readOnly
                    type="radio"
                    id={`inline-radio-1`}
                  />
                </div>
              ))}
            </Form>
            <div className="comments-iput-wrapper">
              {is_comments && typeof comments[0] === 'string'  ? (
                <div className="d-flex">
                  <span className="f-w-700 c-black">{primarycomment} Comment: </span>
                  <span className="commentStyle ml-10">{comments[0]}</span>
                </div>
              ) : is_comments || reporting_to === userId || manager_reporting_to === userId || synergy_lead_reporting_to === userId ? (
                <>
                <div className="d-flex">
                  <StarRating
                    value={typeof comments[0] === "number" ? comments[0] : (comment[questionId] || 0)}
                    starColor={"#F07F1E"}
                    label={`${primarycomment} Rating`}
                    isSelectable={!is_comments}
                    handleCommentChange={(e, rating) => this.handleCommentChange(e, questionId)}
                  />
                 </div>  
                  {/* <p className="mb-2">{primarycomment} Comment</p> */}
                  {/* <textarea  defaultValue={comment[questionId] === null ? "" : comment[questionId]}
                    placeholder="Add Comment"
                    // disabled={is_comments}
                    value={comment[questionId]}
                    onChange={(e) => this.handleCommentChange(e.target.value, questionId)} /> */}
                  {/* {!(comment[questionId] || comments[0] !== null) && (<i className={`fa fa-comments-o`} />
                  )} */}

                </>
              ) : (
                <div className="d-flex"> </div>
              )}
            </div>
          </div>
        );
      case 4:
      return (
        <div className="p-2">
          <Form>
            {is_option.map((type, index) => (
              <div key={`inline-radio-${index}`} className="mb-1">
                <Form.Check
                  inline
                  label={type}
                  name="group1"                  
                  checked={Array.isArray(answer) ? answer.includes(index.toString()) : false}
                  type="checkbox"
                  id={`inline-radio-${index}`}
                />
              </div>
            ))}
          </Form>
          <div className="comments-iput-wrapper">
            {is_comments && typeof comments[0] === 'string' ? (
              <div className="d-flex">
                <span className="f-w-700 c-black">{primarycomment} Comment: </span>
                <span className="commentStyle ml-10">{comments[0]}</span>
              </div>
            ) : is_comments || reporting_to === userId || manager_reporting_to === userId || synergy_lead_reporting_to === userId ? (
              <>
                  <div className="d-flex">
                  <StarRating
                    value={typeof comments[0] === "number" ? comments[0] : (comment[questionId] || 0)}
                    starColor={"#F07F1E"}
                    label={`${primarycomment} Rating`}
                    isSelectable={!is_comments}
                    handleCommentChange={(e, rating) => this.handleCommentChange(e, questionId)}
                  />
                 </div>  
                {/* <p className="mb-2">{primarycomment} Comment</p>
                <textarea  defaultValue={comment[questionId] === null ? "" : comment[questionId]}
                  placeholder="Add Comment"
                  // disabled={is_comments}
                  value={comment[questionId]}
                  onChange={(e) => this.handleCommentChange(e.target.value, questionId)} /> */}
                {/* {!(comment[questionId] || comments[0] !== null) && (<i className={`fa fa-comments-o`} />
                )} */}

              </>
            ) : (
              <div className="d-flex"> </div>
            )}
          </div>
        </div>
      );
      default:
      return <></>;
    }
  };

  /** 
   * check validation for null value in inputfield
   */
  answerValidation = () => {
    let inputData = Object.keys(this.state.comment);
    return (inputData.some(item => this.state.comment[item] === null || this.state.comment[item] === '' || this.state.comment[item] === undefined));
  }


  saveComments = (e, type) => {
    const { comment, answerList, chapterComment, leadcomment, managercomment ,synergyleadcomment,leaddate,managerdate,synergyleaddate,leadrating,managerrating,synergyleadrating } = this.state;
    const commentKeys = Object.keys(comment);
    let savetype = ["save","savemanager","savesynergylead"];

    this.setState({
      saveComments: true,
    });

    var leaddefaultdate = this.state.filter_start_date === "" ? moment().format("YYYY-MM-DD") : moment(this.state.filter_start_date).utcOffset('+05:30').format('YYYY-MM-DD');
    var managerdefaultdate = this.state.filter_manager_start_date === "" ? moment().format("YYYY-MM-DD") : moment(this.state.filter_manager_start_date).utcOffset('+05:30').format('YYYY-MM-DD');
    var synergyleaddefaultdate = this.state.filter_synergy_lead_start_date === "" ? moment().format("YYYY-MM-DD") : moment(this.state.filter_synergy_lead_start_date).utcOffset('+05:30').format('YYYY-MM-DD');
  
    let dataObject = {
      id: parseInt(this.props.actionProperty.answerId),
      comment, chapterComment,
      managercomment : managercomment,
      leadcomment : leadcomment,
      synergyleadcomment : synergyleadcomment,
      leadrating : leadrating === null ? this.state.leadrating : leadrating,
      managerrating : managerrating === null ? this.state.managerrating : managerrating, 
      synergyleadrating : synergyleadrating === null ? this.state.synergyleadrating : synergyleadrating,
      leaddate : leaddate === null && this.state.filter_start_date === ""  || this.state.filter_start_date !== "" ? leaddefaultdate : leaddate,
      managerdate : managerdate === null && this.state.filter_manager_start_date === "" || this.state.filter_manager_start_date !== "" ? managerdefaultdate : managerdate,
      synergyleaddate : synergyleaddate === null && this.state.filter_synergy_lead_start_date === "" || this.state.filter_synergy_lead_start_date !== "" ? synergyleaddefaultdate : synergyleaddate,
      userId: answerList[0]?.userId,
      isComments: (savetype.includes(type) ) ? 1 : 0,
      isManagerComment: (type == 'savemanager' || type == 'savesynergylead') ? 1 : 0,
      isSynergyLeadComments: (type == 'savesynergylead') ? 1 : 0
    };
    let payload = {};
    payload["data"] = dataObject;
    let saveDraftValidation = Object.values(payload.data.comment).some(value => value !== null && value !== undefined && value !== '');

    if (type == 'save') {
      if (!this.answerValidation() && !this.overAllCommentValidation()   && commentKeys.length === answerList.length) {
        this.setState({ loading: true });
        this.props.addComment(payload).then(
          (_response) => {
            this.setState({
              loading: false,
            });
            this.props.handleCommentsQuestion();
            this.ToastAlert("success", "Review form saved successfully");
          },
          (error) => {
            this.setState({
              loading: false,
            });
            this.ToastAlert("error", "Something went wrong");
          }
        );
      }
      else{
        this.ToastAlert('error','Ensure that all mandatory comment fields are completed');
      }
    } else {
      if (saveDraftValidation) {
        this.setState({ loading: true });
        this.props.addComment(payload).then(
          (_response) => {
            this.setState({
              loading: false,
            });
            this.props.handleCommentsQuestion();
            var message = type == 'draft' ? 'as draft' : '';
            this.ToastAlert("success", "Review form saved "+ message +" successfully");
          },
          (error) => {
            this.setState({
              loading: false,
            });
            this.ToastAlert("error", "Something went wrong");
          }
        );
      }
      else {
        this.setState({
          loading: false,
        });
        this.ToastAlert("error", "Need to answer atleast one question");
        return false;
      }
    }
  };

  handleDownloadReport = () =>{
    let dataObject = this.props.pmsDetails.getAnswer[0];
    this.props.handleDownloadReport(dataObject);
  }

  performanceDateYearhandle = (e) => {
    this.setState({
      performanceDateYear: e,
      performanceBatch: { label: "April", value: 1}
    },() => {
      this.getAnswer();
    });
  }
  
  performanceBatchhandle = (e) => {
    this.setState({
      performanceBatch: e,
    },() => {
      this.getAnswer();
    });
  }

  render() {
    let {
      handleCommentsQuestion,
      actionProperty: { userName, roleName, status, reporting_to = null , manager_reporting_to = null , synergy_lead_reporting_to = null, practice_head = null },
      pageFrom,
      userId,leadoverallcomment,
      addClass,
    } = this.props;
    const { answerList, saveComments, comment, isCommented,leadcomment,leadrating,leaddate,isComment, managercomment,synergyleadcomment} = this.state;
    const formattedCurrentDate = moment().format("YYYY-MM-DD");

    const leadDate = moment(answerList[0]?.leaddate).format("DD/MM/YYYY");
    const managerDate = moment(answerList[0]?.managerdate).format("DD/MM/YYYY");
    const synergyLeadDate = moment(answerList[0]?.synergyleaddate).format("DD/MM/YYYY");
    reporting_to = reporting_to === null ? this.props.pmsDetails.getAnswer &&
      this.props.pmsDetails.getAnswer[0]?.reporting_id: reporting_to;

    manager_reporting_to = manager_reporting_to === null ? this.props.pmsDetails.getAnswer &&
    this.props.pmsDetails.getAnswer[0]?.manager_reporting_id : manager_reporting_to;

    synergy_lead_reporting_to = synergy_lead_reporting_to === null ? this.props.pmsDetails.getAnswer &&
    this.props.pmsDetails.getAnswer[0]?.synergy_lead_reporting_id :synergy_lead_reporting_to;

    practice_head = practice_head === null ? this.props.pmsDetails.getAnswer &&
    this.props.pmsDetails.getAnswer[0]?.practice_head: practice_head;
    var primarycomment;
    if (reporting_to && reporting_to === manager_reporting_to) {
      primarycomment = "Manager";
      } else if (reporting_to && reporting_to === synergy_lead_reporting_to) {
          primarycomment = "Synergy Lead";
      }else if (reporting_to && reporting_to === practice_head) {
        primarycomment = "Director";
      } else {
          primarycomment = "Lead";
      }
    return (
      <>
        {this.state.loading ? (
          <div id="preloader">
            <div id="status"></div>
          </div>
        ) : (
          <div className={`page-content px-4 ${addClass ? "active" : ""}`}>
            <Row>
              <div className="col-xs-12 fourty-fluid fourty-fluid">
                {pageFrom === 1 && (
                  <Row>
                    <div className="col-sm-4 col-sm-4 col-xs-12 col-md-12 text-left">
                      <p className="B-G-bold d-gray pull-left mt-10 page-head c-pointer" onClick={handleCommentsQuestion}
                      >
                        <i className="fs-16 fa fa-angle-left mr-5" />
                        Back
                      </p>
                    </div>
                  </Row>
                )}
                <Stack gap={3} className="bg-white mt-10">
                  {pageFrom === 1 && (
                    <>
                    <div className="p-2 default-bg d-flex justify-content-between b-r-3 px-3">
                      {" "}
                      <div>
                        <span>{userName} -</span>{" "}
                        <span className="default-color">{roleName}</span>
                      </div>
                      <div>
                        <span className="default-color">Status:</span>{" "}
                        <span
                          className={`${getBackEndConstant().statusClasses[
                            status.toLowerCase()
                          ]
                            }-color ml-5 t-t-upper`}
                        >
                          {status}
                        </span>
                      </div>
                    </div>
                    { (reporting_to != userId && manager_reporting_to != userId && synergy_lead_reporting_to != userId) &&
                        <Alert variant="warning">
                          <p>
                            <i className='fa fa-exclamation-circle'></i> You can not add comment to this employee, because he/she is not reporting to you. 
                          </p>
                      </Alert>    
                    }
                    </>
                  )}
                  {pageFrom === 5 && (
                    <div>
                      <div className="p-2 default-bg b-r-3 px-3 mt-4">
                        <span className="t-t-upper">Self Review</span>
                      </div>
                      <Row
                        xs={2}
                        md={2}
                        lg={2}
                        className="p-2"
                      >
                        <Col className={`text-left d-flex flex-column w-10 text-start align-items-end`}>
                          <div className="d-flex align-items-baseline performance-year">
                            <label className="m-r-20">
                              Select Year
                            </label>
                            <div className="selectpicker_dropdown hide_bar selectpicker_dropdown_orange">
                            <Select
                              className="basic-single-select"
                              classNamePrefix={"select_dropdown"}
                              onChange={(e) => this.performanceDateYearhandle(e)}
                              value={this.state.performanceDateYear}
                              options={this.props.getPerformanceDateYearOption}
                            ></Select>
                            </div>
                          </div>
                        </Col>
                        <Col className={`text-left d-flex flex-column w-10 text-start`}>
                          <div className="d-flex align-items-baseline performance-year">
                            <label className="m-r-20">
                              Select Batch
                            </label>
                            <div className="selectpicker_dropdown hide_bar selectpicker_dropdown_orange">
                            <Select
                              className="basic-single-select"
                              classNamePrefix={"select_dropdown"}
                              onChange={(e) => this.performanceBatchhandle(e)}
                              value={this.state.performanceBatch}
                              options={this.props.performanceMonthOptions}
                            ></Select>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  )}
                  {answerList.length > 0 ? (
                    answerList?.map((item, index) => {
                      console.log(answerList);
                      return (
                        <Stack
                          gap={1}
                          key={`questionwrapper${index}`}
                          className="px-4 text-start comment-section"
                        >
                          <div className="p-2 text-color-black">
                              <div dangerouslySetInnerHTML={{ __html: index + 1 +'.' + item.question }} />  
                          </div>
                          {this.getAnswerTypeElement(item)}
                          {saveComments && !comment[item.questionId] && (
                            <span className="comment-error">
                             {primarycomment} Rating Is Required
                            </span>
                          )}
                        </Stack>
                      );
                    })
                   ) : (
                    <div>No data available</div>
                  )}
                  <div className="comments-iput-wrapper px-4 text-start " >
                    {answerList.length > 0  &&  Number(answerList[0]?.is_comments) !== 0 && (answerList[0]?.userId === userId && answerList[0]?.leaddate <= formattedCurrentDate || answerList[0]?.userId !== userId)   ? (
                      <>
                        <Row>
                          <Col className="col-lg-12">
                            <div className="d-flex">
                              <span className="f-w-700 c-black mb-2">{primarycomment} Overall Comment:  </span>
                              <span className="commentStyle ml-10">{answerList[0]?.leadoverallcomment}</span>
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col className="col-lg-12">
                            {answerList[0]?.leadrating ? (
                              <div className="d-flex">
                                <span className="f-w-700 c-black mb-2">{primarycomment} Overall Rating:  </span>
                                  <StarRating
                                  value={answerList[0]?.leadrating}
                                  starColor={"#F07F1E"}
                                  isSelectable={false}
                                  className="mr-20"
                                  />
                              </div>
                              ) : (
                                <div>
                                  <p className="mb-2 text-start">{primarycomment} Overall Rating: </p>
                                  <StarRating
                                      value={this.state.leadrating || 0}
                                      className="pb-2 p-2 mb-2 text-start"
                                      starColor={"#2F80ED"}
                                      isSelectable={true}
                                      handleRatingChange={(e, rating) =>
                                          this.handleRatingChange(e, answerList[0]?.userId)
                                      }
                                  />
                                </div>
                            )}
                          </Col>
                        </Row>
                       
                          <div>
                            {console.log("answerList[0]?.userId", answerList[0]?.userId)} 
                          </div>
                        {answerList[0]?.userId !== userId && (
                        <Row>
                          {/* <Col className="col-lg-4">
                              <div className="f-w-700 c-black mb-2">{primarycomment} Comments Visible From:  </div>
                              <div className="row mb-2 p-l-9 date_picker_align pms-date_picker">
                                <DatePicker
                                  className="datepicker-control-section pb-2 p-2 mb-2 border"
                                  onChange={(e) => {
                                    this.setStartDateForFilter(e);
                                    this.setState({ selectedDate: e });
                                  }}
                                  dateFormat="dd/MM/yyyy"
                                  showDayMonthYearPicker
                                  todayButton={"Today"}
                                  name="start_date"
                                  value={this.state.selectedDate || leadDate}
                                  selected={this.state.filter_start_date}
                                  disabled={reporting_to !== userId}
                                />
                                <span className="cal_icon_date" role="button" tabIndex="0">
                                  <i className="icon-calendar"></i>
                                </span>
                              </div>
                          </Col> */}
                        </Row>
                        )}
                      </>
                      ) : reporting_to === userId || manager_reporting_to === userId || synergy_lead_reporting_to === userId ? (
                        <>
                          <Row className="position-relative">
                          <Col className="col-lg-12">
                            <p className="mb-2 text-start">{primarycomment} Overall Comment</p>
                            <textarea
                              defaultValue={answerList[0]?.leadoverallcomment ?? ""}
                              placeholder="Add Comment"
                              className="pb-2 p-2 mb-2 w-100"
                              value={this.state.leadcomment}
                              onChange={(e) => this.handleLeadCommentChange(e.target.value)}
                            />
                            {this.state.saveComments && (!this.state.leadcomment || this.state.leadcomment.trim() === "") && (
                              <span className="comment-error text-end">{primarycomment} Overall Comment Is Required</span>
                            )}
                          </Col>
                        </Row>
                          <Row>
                            {/* <Col className="col-lg-4">
                                <p className="mb-2 text-start">{primarycomment} Comments Visible Frommm</p>
                                <div className="row p-l-9 mb-2 date_picker_align pms-date_picker">
                                  <DatePicker
                                    className="datepicker-control-section pb-2 p-2 mb-2 border"
                                    onChange={(e) => this.setStartDateForFilter(e)}
                                    dateFormat="dd/MM/yyyy"
                                    showDayMonthYearPicker
                                    todayButton={"Today"}
                                    name="start_date"
                                    placeholderText="Select a date"
                                    selected={this.state.filter_start_date}
                                  />
                                  <span className="cal_icon_date" role="button" tabIndex="0">
                                    <i className="icon-calendar"></i>
                                  </span>
                                </div>
                            </Col> */}
                          </Row>
                          <Row className="position-relative">
                          <Col className="col-lg-12">
                            <Row className="align-items-center">
                              <Col className="col-lg-6">
                                <p className="mb-2 text-start">{primarycomment} Overall Rating</p>
                              </Col>
                              <Col className="col-lg-6 text-end">
                                {this.state.saveComments && !this.state.leadrating && (
                                  <span className="comment-error text-end">{primarycomment} Overall Rating Is Required</span>
                                )}
                              </Col>
                            </Row>

                            <div className="d-flex flex-column">
                              <StarRating
                                value={this.state.leadrating || 0}
                                className="pb-2 p-2 mb-2 text-start"
                                starColor={"#2F80ED"}
                                isSelectable={true}
                                handleRatingChange={(e, rating) =>
                                  this.handleRatingChange(e, answerList[0]?.userId)
                                }
                              />
                            </div>
                          </Col>
                        </Row>
                        </>
                      ) : (<div className="d-flex"></div>
                    )}
                  </div>
                  <div className="comments-iput-wrapper px-4 text-start" >
                        {answerList[0]?.managercomments && (answerList[0]?.userId !== userId || answerList[0]?.userId === userId && answerList[0]?.managerdate <= formattedCurrentDate)  ? (
                          <>
                            <Row>
                              <Col className="col-lg-12">
                                <div className="d-flex">
                                  <span className="f-w-700 c-black mb-2">Manager Overall Comment: </span>
                                  <span className="commentStyle ml-10">{answerList[0]?.managercomments}</span>
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col className="col-lg-12">
                                  <div className="d-flex">
                                    <span className="f-w-700 c-black mb-2">Manager Overall Rating:  </span>
                                    <StarRating
                                      value={answerList[0]?.managerrating ? answerList[0]?.managerrating : this.state.managerrating}
                                      starColor={"#F07F1E"}
                                      isSelectable={false}
                                      className="mr-20"
                                    />
                                </div>
                              </Col>
                            </Row>
                            {answerList[0]?.userId !== userId && (
                            <Row>
                              {/* <Col className="col-lg-4">
                                <div className="f-w-700 c-black mb-2">Manager Comments Visible From:  </div>
                                <div className="row mb-2 p-l-9 date_picker_align pms-date_picker">
                                    <DatePicker
                                      className="datepicker-control-section pb-2 p-2 mb-2 border"
                                      onChange={(e) => {
                                        this.setManagerStartDateForFilter(e);
                                        this.setState({ selectedManagerDate: e });
                                      }}
                                      dateFormat="dd/MM/yyyy"
                                      showDayMonthYearPicker
                                      todayButton={"Today"}
                                      name="start_date"
                                      value={this.state.selectedManagerDate || managerDate}
                                      selected={this.state.filter_manager_start_date}
                                      disabled={manager_reporting_to !== userId}
                                      />
                                  <span className="cal_icon_date" role="button" tabIndex="0">
                                    <i className="icon-calendar"></i>
                                  </span>
                                </div>
                            </Col> */}
                            </Row>
                            )}
                          </>
                        ) : manager_reporting_to === userId && manager_reporting_to !== synergy_lead_reporting_to && isCommented  ? (
                          <>
                            <Row>
                                <Col className="col-lg-12">
                                    <p className="mb-2 text-start">Manager Overall Comment</p>
                                    <textarea defaultValue={answerList[0]?.managercomments === null ? "" : answerList[0]?.managercomments}
                                    placeholder="Add Comment" className="pb-2 p-2 mb-2"
                                    value={answerList[0]?.managercomments}
                                    onChange={(e) => this.handleManagerCommentChange(e.target.value)} />
                                </Col>
                            </Row>
                            <Row>
                                {/* <Col className="col-lg-4">
                                  <p className="mb-2 text-start">Manager Comments Visible From</p>
                                  <div className="row p-l-9 mb-2 date_picker_align pms-date_picker">
                                      <DatePicker
                                        className="datepicker-control-section pb-2 p-2 mb-2 border"
                                        onChange={(e) => this.setManagerStartDateForFilter(e)}
                                        dateFormat="dd/MM/yyyy"
                                        showDayMonthYearPicker
                                        todayButton={"Today"}
                                        placeholderText="Select a date"
                                        name="start_date"
                                        selected={this.state.filter_manager_start_date}
                                      />
                                      <span className="cal_icon_date" role="button" tabIndex="0">
                                        <i className="icon-calendar"></i>
                                      </span>
                                  </div>
                                </Col> */}
                            </Row>
                            <Row>
                                <Col className="col-lg-12">
                                  <p className="mb-2 text-start">Manager Overall Rating:  </p>
                                  <StarRating
                                  value={this.state.managerrating || 0}
                                  starColor={"#2F80ED"}
                                  className="pb-2 p-2 mb-2 text-start"
                                  isSelectable={true}
                                  handleManagerRatingChange={(e, rating) =>
                                    this.handleManagerRatingChange(e, answerList[0]?.userId)
                                  }
                                  />
                                </Col>
                            </Row>
                           </>
                        ) : (<div className="d-flex"></div>
                        )} 
                  </div>
                  <div className="comments-iput-wrapper px-4 text-start">
                    { answerList[0]?.synergyleadcomments && (answerList[0]?.userId !== userId || answerList[0]?.userId === userId && answerList[0]?.synergyleaddate <= formattedCurrentDate)  ? (
                      <>
                          <Row>
                              <Col className="col-lg-12">
                                <div className="d-flex" >
                                  <span className="f-w-700 c-black mb-2">Synergy Lead Overall Comment: </span>
                                  <span className="commentStyle ml-10">{answerList[0]?.synergyleadcomments}</span>
                                </div>
                              </Col>
                              <Col className="col-lg-12">
                                <div className="d-flex" >
                                  <span className="f-w-700 c-black mb-2">Synergy Overall Rating:  </span>
                                      <StarRating
                                      value={answerList[0]?.synergyleadrating ? answerList[0]?.synergyleadrating : this.state.synergyleadrating}
                                      starColor={"#F07F1E"}
                                      isSelectable={false}
                                      className="mr-20"
                                    />
                                </div>
                              </Col>
                              {/* {answerList[0]?.userId !== userId && (
                              <Col className="col-lg-4">
                              <div>
                                <div className="f-w-700 c-black mb-2">Synergy Lead Comments Visible From: </div>
                                <div className="row p-l-9 mb-2 date_picker_align pms-date_picker">
                                    <DatePicker
                                      className="datepicker-control-section pb-2 p-2 mb-2 border"
                                      onChange={(e) => {
                                        this.setSynergyLeadStartDateForFilter(e);
                                        this.setState({ selectedSynergyLeadDate: e });
                                      }}
                                      dateFormat="dd/MM/yyyy"
                                      showDayMonthYearPicker
                                      todayButton={"Today"}
                                      name="start_date"
                                      value={this.state.selectedSynergyLeadDate || synergyLeadDate}
                                      selected={this.state.filter_synergy_lead_start_date}
                                      disabled={synergy_lead_reporting_to !== userId}
                                    />
                                    <span className="cal_icon_date" role="button" tabIndex="0">
                                      <i className="icon-calendar"></i>
                                    </span>
                                  </div>
                                </div>  
                              </Col>
                              )} */}
                          </Row>
                      </>
                    ) : synergy_lead_reporting_to === userId && reporting_to !== synergy_lead_reporting_to && isCommented ? (
                      <>
                        <Row>
                            <Col className="col-lg-12">
                              <p className="mb-2 text-start">Synergy Lead Overall Comment</p>
                              <textarea defaultValue={answerList[0]?.synergyleadcomments === null ? "" : answerList[0]?.synergyleadcomments}
                                placeholder="Add Comment" className="pb-2 p-2 mb-2"
                                value={answerList[0]?.synergyleadcomments}
                                onChange={(e) => this.handleSynergyLeadCommentChange(e.target.value)} />
                            </Col>
                        </Row>
                        <Row>
                            {/* <Col className="col-lg-4">
                                <p className="mb-2 text-start">Synergy Lead Comments Visible From</p>
                                <div className="row p-l-9 mb-2 date_picker_align pms-date_picker">
                                  <DatePicker
                                    className="datepicker-control-section pb-2 p-2 mb-2 border"
                                    onChange={(e) => this.setSynergyLeadStartDateForFilter(e)}
                                    dateFormat="dd/MM/yyyy"
                                    showDayMonthYearPicker
                                    todayButton={"Today"}
                                    name="start_date"
                                    placeholderText="Select a date"
                                    selected={this.state.filter_synergy_lead_start_date}
                                  />
                                  <span className="cal_icon_date" role="button" tabIndex="0">
                                    <i className="icon-calendar"></i>
                                  </span>
                                </div>
                            </Col> */}
                        </Row>
                        <Row>
                          <Col className="col-lg-12">
                              <p className="mb-2 text-start">Synergy Lead Overall Rating:  </p>
                              <StarRating
                              value={this.state.synergyleadrating || 0}
                              starColor={"#2F80ED"}
                              className="pb-2 p-2 mb-2 text-start"
                              isSelectable={true}
                              handleSynergyLeadRatingChange={(e, rating) =>
                                this.handleSynergyLeadRatingChange(e, answerList[0]?.userId)
                              }
                            />
                          </Col>
                        </Row>
                        </>
                      ) : (<div className="d-flex"></div>
                    )}
                  </div>
                  <div className="comments-iput-wrapper">                    
                  </div>
                </Stack>
              {((reporting_to === userId || manager_reporting_to === userId || synergy_lead_reporting_to === userId)  ||  pageFrom === 5) &&answerList[0]?.userId && answerList[0]?.userId !== userId && (
                  <div className="B-G-bold d-gray pull-right mt-10 page-footer default-bg">
                      { this.props.actionProperty.is_comments !== "Completed" && (<Button
                        onClick={(e) => { this.saveComments(e, 'save'); }}
                        value="Submit Comments"
                        className="me-2">SUBMIT COMMENTS
                      </Button>)}
                    {(this.props.actionProperty.is_comments !== "Completed") && pageFrom !== 5  &&
                      <Button
                        onClick={(e) => { this.saveComments(e, 'draft'); }}
                        value="Save as Draft"
                        className="me-2" >SAVE AS DRAFT
                      </Button>
                    }
                    {(this.props.actionProperty.is_comments === "Completed") && pageFrom !== 5 &&
                      <Button
                        onClick={(e) => { this.handleDownloadReport(); }}
                        value="Download PDF"
                        className="me-2" >DOWNLOAD PDF
                      </Button>
                    }
                  </div>
                )}
                {((manager_reporting_to === userId) || pageFrom === 5 ) && isCommented == 1 && answerList[0]?.userId !== userId && (
                  <div className="B-G-bold d-gray pull-right mt-10 page-footer default-bg">
                      {(answerList[0]?.is_manager_comment !== 1)&& <Button
                        onClick={(e) => { this.saveComments(e, 'savemanager'); }}
                        value="Submit Comments"
                        className="me-2">SUBMIT COMMENTS
                      </Button>}
                    {(answerList[0]?.is_manager_comment !== 1) && pageFrom !== 5 &&
                      <Button
                        onClick={(e) => { this.saveComments(e, 'draft'); }}
                        value="Save as Draft"
                        className="me-2" >SAVE AS DRAFT
                      </Button>
                    }
                    {(answerList[0]?.is_manager_comment === 1) && pageFrom !== 5 &&
                      <Button
                        onClick={(e) => { this.handleDownloadReport(); }}
                        value="Download PDF"
                        className="me-2" >DOWNLOAD PDF
                      </Button>
                    }
                  </div>
                )}
                {((synergy_lead_reporting_to === userId ) || pageFrom === 5 ) &&  isCommented == 1 && answerList[0]?.userId !== userId && (
                  <div className="B-G-bold d-gray pull-right mt-10 page-footer default-bg">
                      {(answerList[0]?.is_synergylead_comment !== 1) &&<Button
                        onClick={(e) => { this.saveComments(e, 'savesynergylead'); }}
                        value="Submit Comments"
                        className="me-2">SUBMIT COMMENTS
                      </Button>}
                    {(answerList[0]?.is_synergylead_comment !== 1) && pageFrom !== 5 &&
                      <Button
                        onClick={(e) => { this.saveComments(e, 'draft'); }}
                        value="Save as Draft"
                        className="me-2" >SAVE AS DRAFT
                      </Button>
                    }
                    {(answerList[0]?.is_synergylead_comment === 1) && pageFrom !== 5 &&
                      <Button
                        onClick={(e) => { this.handleDownloadReport(); }}
                        value="Download PDF"
                        className="me-2" >DOWNLOAD PDF
                      </Button>
                    }
                  </div>
                )}
                {/* {(answerList[0]?.userId === userId || pageFrom === 5) && (
                <div className="B-G-bold d-gray pull-right mt-10 page-footer default-bg">
                  {(this.props.actionProperty.is_comments === "Completed") && pageFrom !== 5 &&
                      <Button
                      onClick={(e) => { this.handleDownloadReport(); }}
                        value="Download PDF"
                        className="me-2" >DOWNLOAD PDF
                      </Button>
                  }
                </div>
                )} */}
              </div>
            </Row>
          </div>
        )}
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    pmsDetails: state.pmsDetails,
    addClass: state.pmsDetails.addClass,
  };
};

const mapDispatchToProps = {
  getAnswer,
  addComment,
  setAddClass,
};

export default connect(mapStateToProps, mapDispatchToProps)(PmsComments);