import React, { Component } from "react";
import "./pms.css";
import Select from "react-select";
import {
  getLoginUser,
  getReportFilter,
  getLoginUserPermission,
  getReportFilterPmo,
  getPmsQuestion,
  setAddClass,
  deletePmsQuestion,
  getChapterLead,
  editPmsQuestion,
} from "../../Action";
import { connect } from "react-redux";
import * as constants from "../../Config/Constant";
import PageNotFound from "../Reports/PageNotFound";
import { Row, Col } from "react-bootstrap";

class PmsFilter extends Component {
  render() {
    const {
      stateValue,
      stateValue: { role_id = 0 },
    } = this.props;
    if (stateValue?.redirect === 1 || stateValue?.loginUserData === null) {
      return <PageNotFound url={constants.FRONT_LINK} />;
    } else {
      return (
        <>
          <Row
            xs={2}
            md={4}
            lg={6}
            className="bgwhite service-container p-2 mt-3 mx-0 pb-3 multiselect-scrollbar"
          >
            <Col className={`text-left d-flex flex-column w-25 text-start justify-content-end `}>
              <label className="m-b-0 fs-12 p-t-5 style-label m-r-10">
                Business Unit
              </label>
              {role_id === 8 || role_id === 1 || (role_id === 10 && this.props.reportFilter.directors?.length > 1) || stateValue.pmsPermission ? (
                <div className="selectpicker_dropdown w-100 hide_bar w-100 selectpicker_dropdown_orange">
                  <Select
                    className="basic-single-select"
                    classNamePrefix={"select_dropdown"}
                    placeholder="All"
                    isClearable
                    // isClearable={role_id === 1}
                    isSearchable={true}
                    onChange={(e) => this.props.getReportByHead(e)}
                    value={stateValue.selectBuFilter}
                    options={stateValue.buName}
                  ></Select>
                </div>
              ) : (
                <div className="selectpicker_dropdown bu-name">
                  <div className="style-service fs-20">
                    {stateValue?.loginUserData?.business_unit?.bu_name}
                  </div>
                </div>
              )}
            </Col>
            <Col className={`text-left d-flex flex-column w-25 text-start justify-content-end`}>
              <label className="m-b-0 fs-12 p-t-5 style-label m-r-10">
                Line Of Service
              </label>
              {role_id === 8 || role_id === 1 || stateValue.pmsPermission || role_id === 10 ? (
                <div className="selectpicker_dropdown w-100 hide_bar w-100 selectpicker_dropdown_orange">
                  <Select
                    className="basic-single-select"
                    classNamePrefix={"select_dropdown"}
                    placeholder="All"
                    isClearable
                    isSearchable={true}
                    onChange={(e) => this.props.filterService(e)}
                    value={stateValue?.selectService}
                    options={stateValue?.serviceName}
                    isMulti
                  ></Select>
                </div>
              ) : (
                <div className="selectpicker_dropdown bu-name">
                  <div className="style-service fs-20">
                    {stateValue?.loginUserData?.business_unit?.serviceName}
                  </div>
                </div>
              )}
            </Col>

            <Col className={`text-left d-flex flex-column w-25 text-start justify-content-end`}>
              <label className="m-b-0 fs-12 p-t-5 style-label m-r-10">
                  Chapter
              </label>
              {(role_id === 8 || role_id === 1 || stateValue.pmsPermission || role_id === 10) ? (
                <div>
                  <div className="selectpicker_dropdown hide_bar w-100 selectpicker_dropdown_orange">
                    <Select
                      className="basic-single-select"
                      classNamePrefix={"select_dropdown"}
                      placeholder="All"
                      isClearable
                      isSearchable={true}
                      options={stateValue?.chapterLead}
                      value={stateValue?.chapterLeadValue}
                      onChange={(e) => this.props.handleChapter(e)}
                      isMulti
                    />
                  </div>
                </div>
              ) : (
                <div className="selectpicker_dropdown bu-name">
                  {role_id !== 10 ? (
                    <div className="style-service fs-20">
                      <span>
                        {stateValue?.loginUserData?.chapter_name !== "null" && stateValue?.loginUserData?.chapter_name !== null ? stateValue.loginUserData.chapter_name: 'Not available'}
                      </span>
                    </div>
                  ) : (
                    <div></div>
                  )}
                </div>
              )}


            </Col>

            {(role_id === 8 || role_id === 10 || role_id === 1 || stateValue.pmsPermission) && (
              <Col className="d-flex justify-content-center align-items-end">
                <button
                  className="btn btn-primary p-2"
                  onClick={(e) => this.props.generateFilterResult(e)}
                >
                  Apply Filter
                </button>
              </Col>
            )}
          </Row >

        </>
      );
    }
  }
}
const mapStateToProps = (state) => {
  return {
    loginDetails: state.loginDetails,
    reportFilter: state.reportFilterDetails.reportFilter,
    pmoFilter: state.reportFilterDetails.pmoFilter,
    pmsQuestionList: state.pmsDetails.pmsQuestion,
    deletePms: state.pmsDetails.question,
    chapterLeadList: state.pmsDetails.chapterLead,
    addClass: state.pmsDetails.addClass,
  };
};

const mapDispatchToProps = {
  getLoginUser,
  getReportFilter,
  getLoginUserPermission,
  getReportFilterPmo,
  getPmsQuestion,
  deletePmsQuestion,
  getChapterLead,
  editPmsQuestion,
  setAddClass,
};

export default connect(mapStateToProps, mapDispatchToProps)(PmsFilter);
