import React, { Component } from "react";
import "./pms.css";
import {
  getLoginUser,
  getReportFilter,
  getLoginUserPermission,
  getReportFilterPmo,
  getPmsQuestion,
  getStartEndDate,
  saveAnswer,
  setAddClass,
  getAnswer,
} from "../../Action";
import { connect } from "react-redux";
import * as constants from "../../Config/Constant";
import Cookies from "js-cookie";
import PageNotFound from "../Reports/PageNotFound";
import { Button, Stack, Form, Row, Col, Modal } from "react-bootstrap";
import Swal from "sweetalert2";
import moment from "moment";
import Select from "react-select";
import PmsComments from "./PmsComments";
import StarRating from "./StarRating";
import { useHistory, withRouter } from "react-router-dom";
const Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  showCloseButton: true,
  customClass: {
    popup: "colored-toast",
    icon: "white-toast-icon",
  },
  timer: 3000,
  onOpen: (toast) => {
    toast.addEventListener("mouseenter", Swal.stopTimer);
    toast.addEventListener("mouseleave", Swal.resumeTimer);
  },
});
class PmsReview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      queryParams: new URLSearchParams(window.location.search),
      redirect: 0,
      sname: [],
      sname1: [],
      report_practice: [],
      report_service: [],
      practiceHead: [],
      loginUserData: {},
      loginUserPermission: {},
      token: "",
      loading: false,
      bu_id: "",
      selectService: [],
      selectBu: [],
      selectBuFilter: [],
      reportList: [],
      serviceName: [],
      checkedList: [],
      usersService: [],
      pmsId: 0,
      showDeleteModal: false,
      pmsPopUp: false,
      isModalClose: false,
      isEditModal: false,
      deletePmsData: "",
      question: [],
      inputFields: [],
      selectedOption: [],
      multiOption: [],
      selectedCheckOption: {},
      rating: 0,
      isPerform: false,
      isSaved: false,
      viewResponse: false,
      url: "",
      answerList: [],
      answerId: "",
      ratingComments: [],
      isRatingInputBox: false,
      isRatingCommentBox: false,
      isLoadRating: true,
      startQuesDate: "",
      endQuesDate: "",
      performanceDateYear: {},
      performanceBatch: {},
      isBatchOptionEnabled: false,
      showModal: false,
      saveType: ""
    };
  }
  /**
   * Notification message
   * @param {*} icon
   * @param {*} title
   * @returns
   */
  ToastAlert(icon, title) {
    return Toast.fire({
      target: document.getElementById("form-modal"),
      icon: `${icon}`,
      title: `${title}`,
    });
  }
  /**
   * Initial function call
   */
  componentDidMount() {
    let token =
      this.state.queryParams.get("token") === null
        ? Cookies.get("auth_data")
        : this.state.queryParams.get("token");
    let url =
      this.state.queryParams.get("url") == null
        ? Cookies.get("url_code")
        : this.state.queryParams.get("url");
    this.setState({
      token: token,
      apiLink: constants.API_LINK,
      angularUrl: constants.FRONT_LINK,
      excelUrl: constants.EXCEL_LINK,
      url: url,
    });
    this.getUser();
  }
  /**
   * Get the login user details
   */
  getUser = () => {
    this.setState({ loading: true });
    this.props.getLoginUser().then((response) => {
      this.props.getLoginUserPermission().then((response) => {
        this.setState({
          loading: false,
          loginUserData: this.props.loginDetails.loginDetails,
          loginUserPermission: this.props.loginDetails.loginUserPermission,
        });

        this.getPerformanceDate();
        this.getDateAndBatch();
        this.manageQuestion();
      });
    });
  };
  getAnswer = () => {
    // const {
    //   actionProperty: { answerId },
    //   pageFrom,
    // } = this.props;
    this.setState({ loading: true, isPerform: false });
    let dataObject = {
      id: this.state?.loginUserData?.id,
      pageFrom: 5,
      performanceDateYear: this.state.performanceDateYear,
      performanceBatch: this.state.performanceBatch,
    };
    let payload = {};
    payload["data"] = dataObject;
    this.props.getAnswer(payload).then(
      (_response) => {
        const isCommented =
          this.props.pmsDetails.getAnswer &&
          this.props.pmsDetails.getAnswer[0]?.is_comments;
        let answerId;
        let answerArray = this.state.inputFields;
        let ratingArray = this.state.ratingComments;
        this.props.pmsDetails.getAnswer.forEach((element) => {
          answerArray[element.questionId] = element.answer || element.answer == 0
            ? element.answer
            : null;
          answerId = element.answerId;
          if (element.questionType == 2 && element.isRatingComment) {
            ratingArray[element.questionId] = element.ratingComment[0]
              ? element.ratingComment[0]
              : null;
          }
        });

        if(this.props.pmsDetails.getAnswer.length > 0 && this.props.pmsDetails.getAnswer[0]?.is_saved === 1) {
          this.setState({
            isPerform: true,
          });
        }

        this.setState(
          {
            loading: false,
            answerList: this.props.pmsDetails.getAnswer,
            inputFields: answerArray,
            answerId: answerId,
            ratingComments: ratingArray,
            isCommented,
          },
          () => { }
        );
      },
      (error) => {
        this.setState({
          loading: false,
        });
        this.ToastAlert("error", "Something went wrong");
      }
    );
  };
  /**
   * Show the data for PMS question
   */
  manageQuestion = () => {
    let dataObj = {};
    let payload = {};
    let los = [];

    if(Object.keys(this.state.loginUserData.business_unit).length > 0){
      los.push({ id: this.state.loginUserData.business_unit.service_id, name: this.state.loginUserData.business_unit.serviceName, bu_id: this.state.loginUserData.business_unit.id, bu_name: this.state.loginUserData.business_unit.bu_name});
    }

    dataObj.business_unit = this.state.loginUserData.business_unit
      ? this.state.loginUserData.business_unit
      : [];
    dataObj.los = los;
    dataObj.chapter = this.state.loginUserData.chapter_name !== "null" && this.state.loginUserData.chapter_name !== null ? [this.state.loginUserData.chapter_name] : [];
    dataObj.date = "";
    dataObj.pageFrom = 5; //to differentiate the page data from reveiw
    payload["data"] = dataObj;
    this.setState({
      loading: true,
    });
    this.props.getPmsQuestion(payload).then(
      (response) => {
        let questionArray = {};
        let devRatingComment = {};
        let ratingValidation = {};
        let startQuesDate = "";
        let endQuesDate = "";
        this.props.pmsQuestionList.forEach((element) => {
          questionArray[element.id] = null;
          devRatingComment[element.id] = null;
          if (element.question_type == 2 && element.isRatingComment) {
            ratingValidation[element.id] = element.ratingComment
              ? element.ratingComment
              : null;
          }
        });
        this.setState(
          {
            loading: false,
            pmsQuestion: this.props.pmsQuestion,
            inputFields: { ...questionArray },
            // ratingComment: { ...devRatingComment },
            ratingComments: { ...ratingValidation }
          },
          () => {
            this.getAnswer();
          }
        );
      },
      (error) => {
        this.ToastAlert("error", error);
      }
    );
  };
  /**
   * Additional input ,mcq,rating
   * @param {*} index
   * @param {*} event
   */
  handleInputChange = (event, questionId, key, type) => {
    console.log("event", event);
  
    let value = ""; // Initialize value variable
  
    if (type === 1) {
      value = event.target.value;
    } else if (type === 3) {
      this.setState((prevState) => ({
        selectedOption: {
          ...prevState.selectedOption,
          [questionId]: event,
        },
      }));
      value = event;
    } else if (type === 4) {
      value = key.toString(); // Ensure value is stored as a string
  
      this.setState((prevState) => {
        let multiOptions = prevState.inputFields[questionId];
  
        // Ensure multiOptions is always an array
        if (!Array.isArray(multiOptions)) {
          multiOptions = [];
        }
  
        if (multiOptions.includes(value)) {
          multiOptions = multiOptions.filter((v) => v !== value);
        } else {
          multiOptions.push(value);
        }
  
        return {
          inputFields: {
            ...prevState.inputFields,
            [questionId]: multiOptions, // Store updated selections
          },
        };
      });
  
      return; // Avoid extra state update at the end
    } else {
      value = event;
      this.setState((prevState) => ({
        isRatingCommentBox: true,
        rating: {
          ...prevState.rating,
          [parseInt(questionId)]: event,
        },
      }));
    }
  
    this.setState((prevState) => ({
      inputFields: {
        ...prevState.inputFields,
        [parseInt(questionId)]: value,
      },
    }));
  
    console.log("Updated inputFields:", this.state.inputFields);
  };
  
  
  handleCheckBoxInputChange = (questionOption, questionId, event, questionType) => {
    const { checked, value } = event.target;
    this.setState((prevState) => {
      const selectedCheckOption = { ...prevState.selectedCheckOption };
      if (checked) {
        // Add the value if checked and ensure uniqueness using Set
        if (!selectedCheckOption[questionId]) {
          selectedCheckOption[questionId] = [];
        }
        const updatedSet = new Set(selectedCheckOption[questionId]);
        updatedSet.add(value);
        selectedCheckOption[questionId] = Array.from(updatedSet);
      } else {
        // Remove the value if unchecked
        selectedCheckOption[questionId] = selectedCheckOption[questionId].filter((v) => v !== value);
      }
      return { selectedCheckOption };
    }, () => {
      console.log('selectedCheckOption', this.state.selectedCheckOption);
      const selectCheck = this.state.selectedCheckOption;
      this.handleInputChange(selectCheck, questionId, questionOption, questionType);
    });
  };
  /**
   * check validation for null value in inputfield
   */
  answerValidation = () => {
    let inputData = Object.keys(this.state.inputFields);
    return inputData.some(
      (item) =>
        this.state.inputFields[item] === null ||
        this.state.inputFields[item] === "" ||
        this.state.inputFields[item] === undefined
    );
  };
  commentValidation = () => {
    let commentData = Object.values(this.state.ratingComments);
    const commentValidation = commentData.some(
      (item) => item === null || item === "" || item === undefined
    );
    return commentValidation; // Return true if all items are not null, empty, or undefined
  };
  handleRatingComments = (event, questionId) => {
    this.setState((prevState) => ({
      ratingComments: {
        ...prevState.ratingComments,
        [parseInt(questionId)]: event.target.value,
        // Use questionId as the key
      },
      isLoadRating: true,
    }));
  };
  /**
   * get values for question
   * @param {*} type
   */
  saveFinalAnswer = (event, type) => {
    let isPerform,
      dataObj = {},
      payload = {};
    dataObj.answer = this.state.inputFields;
    dataObj.userId = this.state.loginUserData.id;
    dataObj.answerId = this.state.answerId;
    dataObj.ratingComments =
      this.state.ratingComments !== null ? this.state.ratingComments : "";
    // dataObj.ratingComments = this.state.ratingComments;
    // dataObj.ratingComments = this.state.ratingComments ? this.state.ratingComments : '';
    dataObj.isSaveValue = type == "save" ? 1 : 0;
    dataObj.answerDate = moment().startOf("month").format("YYYY-MM-DD");
    payload["data"] = dataObj;
    let saveDraftValidation = Object.values(payload.data.answer).some(
      (value) => value !== null && value !== undefined && value !== ""
    );
    if (type === "save") {
      this.setState({
        isSaved: true,
      });
      if (
        !this.commentValidation() &&
        !this.answerValidation() &&
        Object.keys(this.state.inputFields).length ===
        this.props.pmsQuestionList.length
      ) {
        this.setState({
          showModal: true,
          saveType: "save", // Store the type instead of a function
        });
        return;
      }else{
        this.ToastAlert('error','Ensure all required questions are answered');
      }
    } else {
      this.setState({
        isSaved: false,
      });
      if (saveDraftValidation) {
        this.props.saveAnswer(payload).then((response) => {
          if (
            this.props.saveAnswerList.data &&
            this.props.saveAnswerList.data.is_saved === 1
          ) {
            isPerform = true;
          }
          this.setState({
            loading: false,
            isPerform: isPerform,
            loading: true,
          });
          this.ToastAlert("success", "Review form saved as draft successfully");
          this.props.history.push(`/pms_dashboard`);
        });
      } else {
        this.ToastAlert(
          "error",
          "Please answer at least one question to save the form"
        );
      }
    }
  };

  //Function to handle save when modal is confirmed
  handleConfirmSave = async (type) => {
    this.setState({ loading: true, showModal: false });
    let isPerform = false;
    let dataObj = {
      answer: this.state.inputFields,
      userId: this.state.loginUserData.id,
      answerId: this.state.answerId,
      ratingComments: this.state.ratingComments || "",
      isSaveValue: type === "save" ? 1 : 0,
      answerDate: moment().startOf("month").format("YYYY-MM-DD"),
    };
    let payload = { data: dataObj };

    try {
      const response = await this.props.saveAnswer(payload); // Ensure awaiting the API call

      if (response?.data?.is_saved === 1) {
        isPerform = true;
      }

      this.setState({
        loading: false,  
        isPerform: isPerform
      });

      this.ToastAlert(
        "success",
        type === "save"
          ? "Review form submitted successfully"
          : "Review form saved as draft successfully"
      );

      this.props.history.push(`/pms_dashboard`);
    } catch (error) {
      console.error("Error saving answer:", error);
      this.setState({ loading: false });
      this.ToastAlert("error", "Failed to save review form. Please try again.");
    }
  };


  // Close modal without saving
  handleCloseModal = () => {
    this.setState({ showModal: false });
  };

  setRatingComments = (value, question) => {
    if (value.question_type == 2) {
      this.setState(
        {
          ratingComments: {
            ...this.state.ratingComments,
            [value.questionId]: null,
          },
        },
        () => { }
      );
    }
  };
  getAnswerTypeElement = (item) => {
    const {
      questionType,
      questionId,
      answer,
      is_option,
      is_comments,
      comments,
      ratingComment,
      isRatingComment,
    } = item;
    // const {
    //   userId,
    //   actionProperty: { reportting = null },
    // } = this.props;
    const { comment } = this.state;
    let checkedOption = null;
    let multiOption = null;
    
    if(questionType == 3){
      checkedOption = this.state.selectedOption[item.questionId] ||  this.state.selectedOption[item.questionId] == 0 ? this.state.selectedOption[item.questionId] : answer;
    }
    if(questionType == 4){
      multiOption = this.state.selectedOption[item.questionId] ||  this.state.selectedOption[item.questionId] == 0 ? this.state.selectedOption[item.questionId] : answer;
    }
    switch (questionType) {
      case 1:
        return (
          <div className="p-2">
            <div className="pb-2">
              <textarea
                defaultValue={answer ? answer : []}
                className="form-control"
                onChange={(e) => this.handleInputChange(e, questionId, "", 1)}
              />
              {this.state.isSaved &&
                (this.state.inputFields[questionId] === "" ||
                  this.state.inputFields[questionId] === null ||
                  this.state.inputFields[questionId] === undefined) && (
                  <span className="comment-error">This field is required.</span>
                )}
            </div>
            <div className="comments-iput-wrapper">
              {is_comments ? (
                <div className="d-flex">
                  <span className="f-w-700 c-black">Comments: </span>
                  <span className="commentStyle ml-10">{comments[0]}</span>
                </div>
              ) : this.state.loginUserData.reporting_to ===
                this.state.loginUserData.id ? (
                <>
                  <textarea
                    value={
                      is_comments ? comments[0] : comment[questionId] || ""
                    }
                    placeholder="Add Comments"
                    disabled={is_comments}
                    onChange={(e) =>
                      this.handleCommentChange(e.target.value, questionId)
                    }
                  />
                  {/* {!(comment[questionId] || is_comments) && (
                    <i className={`fa fa-comments-o`} />
                  )} */}
                </>
              ) : (
                <div className="d-flex"></div>
              )}
            </div>
          </div>
        );
      case 2:
        return (
          <Stack
            direction="horizontal"
            gap={2}
            className="pb-2 p-2 star-wrapper"
          >          
            <StarRating
              value={answer ? answer : this.state.rating}
              starColor={"#F07F1E"}
              isSelectable={true}
              className="mr-20"
              label=""
              handleCommentChange={(e) => this.handleInputChange(e, questionId)}
            />
            {this.state.isRatingInputBox && (
              <div>
                {isRatingComment && ratingComment !== null ? (
                  <div>
                    <textarea
                      placeholder=" Add Comment"
                      defaultValue={ratingComment ? ratingComment : []}
                      className="form-control"
                      onChange={(e) => this.handleRatingComments(e, questionId)}
                    />
                  </div>
                ) : (
                  <div>
                    {/*  <textarea placeholder=" Add Comments" defaultValue={ratingComment ? ratingComment : []}
                      className="form-control" onChange={(e) => this.handleRatingComments(e, questionId)
                      } /> */}
                  </div>
                )}
              </div>
            )}
            {/* {this.state.isSaved &&
              (this.state.ratingComments[questionId] === "" || this.state.ratingComments[questionId] === null ||
                this.state.ratingComments[questionId] ===
                undefined) && (
                <span className="comment-error-rating">
                  Rating Field in required
                </span>
              )} */}
            {this.state.isLoadRating && (
              <div>
                {isRatingComment && ratingComment != null ? (
                  <div className="position-relative textarea-rightpadding">
                    <span className="text-red right-asterisk">*</span>
                    <textarea
                      placeholder="Add Comment"
                      defaultValue={ratingComment ? ratingComment : []}
                      className="form-control custom-textarea"
                      onChange={(e) => this.handleRatingComments(e, questionId)}
                    />
                    {this.state.isSaved &&
                      (this.state.ratingComments[questionId] === "" ||
                        this.state.ratingComments[questionId] === null ||
                        this.state.ratingComments[questionId] ===
                        undefined) && (
                        <span className="comment-error-rating">
                          Rating comment field is required.
                        </span>
                      )}
                  </div>
                ) : (
                  <div> </div>
                )}
              </div>
            )}
            {this.state.isSaved &&
              (this.state.inputFields[questionId] === "" ||
                this.state.inputFields[questionId] === null ||
                this.state.inputFields[questionId] === undefined) && (
                <span className="comment-error">This field is required.</span>
              )}
            {is_comments ||
              this.state.reporting_to === this.state.loginUserData.id ? (
              <div>
                <StarRating
                  value={is_comments ? comments[0] : comment[questionId] || 0}
                  starColor={"#2F80ED"}
                  label="Chapter Rating"
                  isSelectable={true} //{!is_comments}
                  handleCommentChange={(e, rating) =>
                    this.handleCommentChange(e, questionId)
                  }
                />
                <textarea
                  defaultValue={answer ? answer : []}
                  placeholder=" Add Comments"
                  className="form-control"
                  onChange={(e) => this.handleInputChange(e, questionId, "", 1)}
                />
              </div>
            ) : (
              <></>
            )}
          </Stack>
        );
      case 3:
        return (
          <div className="p-2">
            <Form className="">
              {is_option.map((type, index) => (
                <div key={`inline-radio-${index}`} className="mb-1 checkClass">
                  <Form.Check
                    label={type}
                    name="group1"
                    checked={checkedOption == index
                    }
                    type="radio"
                    id={`inline-radio-${index}`}
                    value={index}
                    onChange={(e) =>
                      this.handleInputChange(index, questionId, e, 3)
                    }
                  />
                </div>
              ))}
              {this.state.isSaved &&
                (this.state.inputFields[questionId] === "" ||
                  this.state.inputFields[questionId] === null ||
                  this.state.inputFields[questionId] === undefined) && (
                  <span className="comment-error">This field is required.</span>
                )}
            </Form>
            <div className="comments-iput-wrapper">
              {is_comments ? (
                <div className="d-flex">
                  <span className="f-w-700 c-black">Comments: </span>
                  <span className="commentStyle ml-10">{comments[0]}</span>
                </div>
              ) : this.state.loginUserData.reporting_to ===
                this.state.loginUserData.id ? (
                <>
                  <textarea
                    value={
                      is_comments ? comments[0] : comment[questionId] || ""
                    }
                    placeholder="Add Comments"
                    disabled={is_comments}
                    onChange={(e) =>
                      this.handleCommentChange(e.target.value, questionId)
                    }
                  />
                  {/* {!(comment[questionId] || is_comments) && (
                    <i className={`fa fa-comments-o`} />
                  )} */}
                </>
              ) : (
                <div className="d-flex"> </div>
              )}
            </div>
          </div>
        );
        case 4:
          return (
            <div className="p-2">
              <Form>
                {is_option.map((type, index) => (
                  <div key={`inline-checkbox-${index}`} className="mb-1">
                    <Form.Check
                      label={type}
                      checked={Array.isArray(this.state.inputFields[questionId]) && 
                              this.state.inputFields[questionId].includes(index.toString())} 
                      name={`group-${questionId}`}
                      type="checkbox"
                      id={`inline-checkbox-${index}`}
                      value={index}
                      onChange={(e) =>
                        this.handleInputChange(e, questionId, index, 4)
                      }
                      // disabled={this.state.isSaved &&  !this.answerValidation() }
                    />
                  </div>
                ))}
                
                {/* Validation Message */}
                {this.state.isSaved &&
                  (!Array.isArray(this.state.inputFields[questionId]) || 
                   this.state.inputFields[questionId].length === 0) && (
                    <span className="comment-error">This field is required.</span>
                  )}
              </Form>
            
            <div className="comments-iput-wrapper">
              {is_comments ? (
          <div className="d-flex">
            <span className="f-w-700 c-black">Comments: </span>
            <span className="commentStyle ml-10">{comments[0]}</span>
          </div>
              ) : this.state.loginUserData.reporting_to ===
          this.state.loginUserData.id ? (
          <>
            <textarea
              value={
                is_comments ? comments[0] : comment[questionId] || ""
              }
              placeholder="Add Comments"
              disabled={is_comments}
              onChange={(e) =>
                this.handleCommentChange(e.target.value, questionId)
              }
            />
          </>
              ) : (
          <div className="d-flex"> </div>
              )}
            </div>
          </div>
        );
      default:
        return <></>;
    }
  };

  getPerformanceDateYearOption = () => {
    const currentYear = new Date().getFullYear();
    const arrayValue = currentYear == 2024 ? 1 : (currentYear == 2025 ? 2 : 3);
    const options = Array(arrayValue)
      .fill(1)
      .map((_item, index) => {
        return {
          id: index,
          label: currentYear - index,
          value: currentYear - index,
        };
      });
    return options;
  };

  performanceDateYearhandle = (e) => {
    let startQuesDate, endQuesDate;
    this.props.dateList.length && this.props.dateList.map((item) => {
      if (this.state.performanceBatch.value==item.batch && e.value==new Date(item.start_date).getFullYear()) {
        startQuesDate = item.start_date;
        endQuesDate = item.end_date;
      }
    });

    this.setState({
      performanceDateYear: e,
      performanceBatch: { label: "April", value: 1},
      startQuesDate,
      endQuesDate
    },() => {
      this.getAnswer();
    });
  }

  performanceBatchhandle = (e) => {
    let startQuesDate, endQuesDate, isBatchOptionEnabled = false;
    this.props.dateList.length && this.props.dateList.map((item) => {
      if (e.value==item.batch && this.state.performanceDateYear.value==new Date(item.start_date).getFullYear()) {
        isBatchOptionEnabled = true;
        startQuesDate = item.start_date;
        endQuesDate = item.end_date;
      }
    });

    this.setState({
      performanceBatch: e,
      isBatchOptionEnabled,
      startQuesDate,
      endQuesDate
    },() => {
      this.getAnswer();
    });
  }

  getPerformanceDate = () => {
    const currentYear = new Date().getFullYear();

    this.setState({
      performanceDateYear: {
        label: currentYear,
        value: currentYear,
      }
    });
  };

  getDateAndBatch = () => {
    let defaultSelectBatch = {};
    let startQuesDate, endQuesDate;
    let isBatchOptionEnabled = false;
    this.props.getStartEndDate().then(
      (response) => {
        this.props.dateList.length &&
          this.props.dateList.map((item) => {
            if (item.status == 0) {
              isBatchOptionEnabled = true;
              startQuesDate = item.start_date;
              endQuesDate = item.end_date;
              defaultSelectBatch = { label: item.batch==1 ? "April" : "October", value: item.batch};
            }
          });

        this.setState({
          isBatchOptionEnabled,
          startQuesDate,
          endQuesDate,
          performanceBatch: defaultSelectBatch
        });
      },
      (error) => {
        this.ToastAlert("error", error);
      }
    );
  };

  render() {
    const formattedstartDate = this.state.startQuesDate;
    const formattedendDate = this.state.endQuesDate;
    const formattedCurrentDate = moment().format("YYYY-MM-DD");
    const businessUnit = this.state.loginUserData.business_unit?.bu_name;
    const serviceName = this.state.loginUserData.business_unit?.serviceName;
    const isDeadLine = formattedendDate && formattedCurrentDate > formattedendDate ? true : false;
    var questionNumber = 0;
    const performanceMonthOptions = [{label: "April", value:1}, {label: "October", value:2}];
    if (this.state.redirect === 1 || this.state.loginUserData === null) {
      return <PageNotFound url={this.state.angularUrl} />;
    } else {
      return (
        <>
          {this.state.loading && (
            <div id="preloader">
              <div id="status"></div>
            </div>
          )}
          {this.state.isPerform || isDeadLine ? (
            this.state.viewResponse ? (
              <PmsComments
                actionProperty={{
                  userName: this.state?.loginUserData?.first_name,
                  roleName: this.state?.loginUserData?.emp_level,
                  status: "completed",
                  answerId: this.state?.loginUserData?.id,
                }}
                pageFrom={5}
                userId={this.state?.loginUserData?.id}
                performanceDateYear={this.state?.performanceDateYear}
                performanceBatch={this.state?.performanceBatch}
                performanceMonthOptions={performanceMonthOptions}
                getPerformanceDateYearOption={this.getPerformanceDateYearOption()}
                moveBack={() => {
                  this.setState({ viewResponse: false });
                }}
              />
            ) : (
              <div
                className={`page-content p-4 h100v ${this.props.addClass ? "active" : ""
                  }`}
              >
                <div className="scrollable bgwhite">
                  <div className="p-2 default-bg b-r-3 px-3 mt-4">
                    <span className="t-t-upper">Self Review</span>
                  </div>
                  <Row
                    xs={2}
                    md={2}
                    lg={2}
                    className="p-2"
                  >
                    <Col className={`text-left d-flex flex-column w-10 text-start align-items-end`}>
                      <div className="d-flex align-items-baseline performance-year">
                        <label className="m-r-20">
                          Select Year
                        </label>
                        <div className="selectpicker_dropdown hide_bar selectpicker_dropdown_orange">
                          <Select
                            className="basic-single-select"
                            classNamePrefix={"select_dropdown"}
                            onChange={(e) => this.performanceDateYearhandle(e)}
                            value={this.state.performanceDateYear}
                            options={this.getPerformanceDateYearOption()}
                          ></Select>
                        </div>
                      </div>
                    </Col>
                    <Col className={`text-left d-flex flex-column w-10 text-start`}>
                      <div className="d-flex align-items-baseline performance-year">
                        <label className="m-r-20">
                          Select Batch
                        </label>
                        <div className="selectpicker_dropdown hide_bar selectpicker_dropdown_orange">
                          <Select
                            className="basic-single-select"
                            classNamePrefix={"select_dropdown"}
                            onChange={(e) => this.performanceBatchhandle(e)}
                            value={this.state.performanceBatch}
                            options={performanceMonthOptions}
                          ></Select>
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <div className="p-5">
                    {isDeadLine && !this.state.isPerform &&
                      <>
                        <h5>Deadline for the review is over.</h5>
                      </>
                    }
                    {this.state.isPerform &&
                      <>
                        <h5>Self Review got successfully submitted</h5>
                        <p
                          className="warning-color underline p-2"
                          onClick={() => this.setState({ viewResponse: true })}
                        >
                          View your response
                        </p>
                      </>
                    }
                  </div>
                </div>
              </div>
            )
          ) : (
            <div
              className={`page-content p-4 h100v ${this.props.addClass ? "active" : ""
                }`}
            >
              <div className="scrollable bgwhite">
                <div className="p-2 default-bg b-r-3 px-3 mt-4">
                  <span className="t-t-upper">Self Review</span>
                  <div>
                    <div className="title-bar style-service">
                      <h3>Manage Question</h3>
                    </div>
                  </div>
                </div>
                <>
                  <Row
                    xs={2}
                    md={2}
                    lg={2}
                    className="p-2"
                  >
                    <Col className={`text-left d-flex flex-column w-10 text-start align-items-end`}>
                      <div className="d-flex align-items-baseline performance-year">
                        <label className="m-r-20">
                          Select Year
                        </label>
                        <div className="selectpicker_dropdown hide_bar selectpicker_dropdown_orange">
                          <Select
                            className="basic-single-select"
                            classNamePrefix={"select_dropdown"}
                            onChange={(e) => this.performanceDateYearhandle(e)}
                            value={this.state.performanceDateYear}
                            options={this.getPerformanceDateYearOption()}
                          ></Select>
                        </div>
                      </div>
                    </Col>
                    <Col className={`text-left d-flex flex-column w-10 text-start`}>
                      <div className="d-flex align-items-baseline performance-year">
                        <label className="m-r-20">
                          Select Batch
                        </label>
                        <div className="selectpicker_dropdown hide_bar selectpicker_dropdown_orange">
                          <Select
                            className="basic-single-select"
                            classNamePrefix={"select_dropdown"}
                            onChange={(e) => this.performanceBatchhandle(e)}
                            value={this.state.performanceBatch}
                            options={performanceMonthOptions}
                          ></Select>
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <Row
                    xs={1}
                    md={1}
                    lg={10}
                    className="bgwhite service-container p-2 mt-3 mx-0  align-items-center justify-content-center"
                  >
                    <Col
                      className={`text-left d-flex flex-column w-30  text-start align-items-center justify-content-center`}
                    >
                      <div className="style-service fs-15">
                        {businessUnit == "null"
                          ? ""
                          : `BUSINESS UNIT : ${businessUnit}`}
                      </div>
                    </Col>
                    <Col
                      className={`text-left d-flex flex-column w-25 text-start align-items-center justify-content-center`}
                    >
                      <div className="selectpicker_dropdown bu-name">
                        <div className="style-service fs-15">
                          {/* {.loginUserData?.business_unit?.serviceName} */}
                          {serviceName == "null"
                            ? ""
                            : `LINE OF SERVICE : ${serviceName}`}
                        </div>
                      </div>
                    </Col>
                    <Col
                      className={`text-left d-flex flex-column w-25 text-start `}
                    >
                      <div className="selectpicker_dropdown bu-name">
                        <div className="style-service fs-15">
                          {/* {.loginUserData?.business_unit?.serviceName} */}
                          CHAPTER :
                           {this.state.loginUserData.chapter_name !== "null" && this.state.loginUserData.chapter_name !== null ? this.state.loginUserData.chapter_name: 'Not available'}
                        </div>
                      </div>
                    </Col>
                  </Row>
                </>
                <div className="p-5">
                  {!this.state.loading && this.state.isBatchOptionEnabled &&
                    this.props.pmsQuestionList.length === 0 && (
                      <span>No data available</span>
                    )}
                  {this.props.pmsQuestionList &&
                    this.props.pmsQuestionList.length > 0 &&
                    formattedstartDate > formattedCurrentDate && (
                      <span>Review Not Yet Started</span>
                    )}
                  {!this.state.isBatchOptionEnabled && (
                    <span>Review Not Yet Started</span>
                  )}
                  {this.state.answerList.length === 0 && (
                    <ul className="text-start p-0">
                      {this.props.pmsQuestionList.length > 0 &&
                        formattedstartDate <= formattedCurrentDate &&
                        this.props.pmsQuestionList.map((question, key) => {
                          return (
                            <li
                              key={question.id}
                              className="comment-section m-3"
                            >
                               <p className="m-b-5">
                                      {  question.mandatory == 0 && 
                                        <div dangerouslySetInnerHTML={{ __html: ++questionNumber +'.' + question.question }} />  
                                      }
                                      {  question.mandatory == 1 && 
                                        <div dangerouslySetInnerHTML={{ __html: ++questionNumber +'.' +'<span class="text-red">*</span>' + question.question}} />  
                                      }
                                      
                                    </p>
                              <br></br>
                              <form className="custom-form mandatory-radio">
                           
                                {question.question_type === 1 && ( //text
                                  <div className="form-group w100">
                                    <textarea
                                      className="form-control"
                                      onChange={(e) =>
                                        this.handleInputChange(
                                          e,
                                          question.id,
                                          "",
                                          1
                                        )
                                      }
                                    />
                                    {this.state.isSaved &&
                                      (this.state.inputFields[question.id] ===
                                        "" ||
                                        this.state.inputFields[question.id] ===
                                        null ||
                                        this.state.inputFields[question.id] ===
                                        undefined) && (
                                        <span className="comment-error">
                                          This field is required.
                                        </span>
                                      )}
                                  </div>
                                )}
                                {question.question_type === 2 && ( //rating
                                  <div
                                    className="form-group star-wrapper w100"
                                    style={{ display: "flex" }}
                                  >
                                    <StarRating
                                      value={this.state.rating}
                                      starColor={"#F07F1E"}
                                      isSelectable={true}
                                      className="mr-20"
                                      label=""
                                      handleCommentChange={(e) =>
                                        this.handleInputChange(e, question.id)
                                      }
                                    />
                                    {/* {question.isRatingComment  */}
                                    {this.state.isRatingCommentBox && (
                                      <div>
                                        {question.isRatingComment ? (
                                          <div className="position-relative textarea-rightpadding">
                                            <span className="text-red right-asterisk">*</span>
                                            <textarea
                                              placeholder=" Add Comments"
                                              defaultValue={
                                                question.ratingComment
                                                  ? question.ratingComment
                                                  : []
                                              }
                                              style={{ marginLeft: "5px" }}
                                              className="form-control"
                                              onChange={(e) =>
                                                this.handleRatingComments(
                                                  e,
                                                  question.id
                                                )
                                              }
                                            />
                                            {this.state.isSaved &&
                                              (this.state.ratingComments[
                                                question.id
                                              ] === "" ||
                                                this.state.ratingComments[
                                                question.id
                                                ] === null ||
                                                this.state.ratingComments[
                                                question.id
                                                ] === undefined) && (
                                                <span className="comment-error-rating">
                                                  Rating comment field is
                                                  required.
                                                </span>
                                              )}
                                          </div>
                                        ) : (
                                          <div></div>
                                        )}
                                      </div>
                                    )}
                                    {this.state.isSaved &&
                                      (this.state.inputFields[question.id] ===
                                        "" ||
                                        this.state.inputFields[question.id] ===
                                        null ||
                                        this.state.inputFields[question.id] ===
                                        undefined) && (
                                        <span className="comment-error">
                                          This field is required.
                                        </span>
                                      )}
                                  </div>
                                )}
                                {question.question_type === 3 && (
                                  <>
                                    {Object.values(question.is_option).map(
                                      (questionOption, keyOption) => (
                                        //keyOption + 1 => this is directly related to pms comments mcq type index + 1
                                        <span className="m-r-20 mb-1">
                                          <input
                                            id={keyOption}
                                            type="radio"
                                            checked={
                                              this.state.selectedOption[
                                              question.id
                                              ] ===
                                              keyOption
                                            }
                                            className="check m-r-10"
                                            value={keyOption}
                                            onChange={(e) =>
                                              this.handleInputChange(
                                                keyOption,
                                                question.id,
                                                e,
                                                3
                                              )
                                            }
                                          />{" "}
                                          {questionOption}
                                        </span>
                                      )
                                    )}
                                    {this.state.isSaved &&
                                      (this.state.selectedOption[
                                        question.id
                                      ] === "" ||
                                        this.state.inputFields[question.id] ===
                                        null ||
                                        this.state.selectedOption[
                                        question.id
                                        ] === undefined) && (
                                        <span className="comment-error">
                                          This field is required.
                                        </span>
                                      )}
                                  </>
                                )}
                                {question.question_type === 4 && (
                                  <>
                                    {Object.values(question.is_option).map((questionOption, keyOption) => (
                                      // keyOption + 1 => this is directly related to pms comments mcq type index + 1
                                      <React.Fragment key={keyOption}>
                                        <div className="form-check">  
                                        <input
                                          id={keyOption}
                                          type="checkbox"
                                          className="form-check-input"
                                          value={keyOption}
                                          onChange={(e) =>
                                            this.handleCheckBoxInputChange(keyOption, question.id, e, 4)
                                          }
                                        />
                                          <label className="form-check-label">{questionOption}</label>
                                        </div>
                                      </React.Fragment>
                                    ))}
                                    {this.state.isSaved &&
                                      (this.state.selectedCheckOption[question.id] === "" ||
                                        this.state.inputFields[question.id] === null ||
                                        this.state.selectedCheckOption[question.id] === undefined) && (
                                        <span className="comment-error">This field is required.</span>
                                      )}
                                  </>
                                )}
                              </form>
                            </li>
                          );
                        })}
                      {this.props.pmsQuestionList &&
                        this.props.pmsQuestionList.length > 0 &&
                        formattedstartDate <= formattedCurrentDate && (
                          <div className="B-G-bold d-gray pull-right mt-10 page-footer default-bg">
                            <Button
                              onClick={(e) => this.saveFinalAnswer(e, "save")}
                              value="Submit"
                              className="me-2"
                            >
                              SUBMIT
                            </Button>
                            <Button
                              onClick={(e) => this.saveFinalAnswer(e, "draft")}
                              value="Save as Drafts"
                              className="me-2"
                            // disabled={this.state?.answerList.length === 0}
                            >
                              SAVE AS DRAFT
                            </Button>
                          </div>
                        )}
                    </ul>
                  )}
                  {/* start answer */}
                  {this.state.answerList.length > 0 ? (
                    this.state.answerList?.map((item, index) => {
                      return (
                        <Stack
                          gap={1}
                          key={`questionwrapper${index}`}
                          className="px-4 text-start comment-section"
                        >
                          <div className="p-2 text-color-black">
                                <div dangerouslySetInnerHTML={{ 
                                  __html: (index + 1) + '. ' + (item.mandatory === 1 ? '<span class="text-red">*</span> ' : '') + item.question 
                                }} /> 
                              </div>                          
                          {this.getAnswerTypeElement(item)}
                        </Stack>
                      );
                    })
                  ) : (
                    <div></div>
                  )}
                  {this.props.pmsQuestionList &&
                    this.props.pmsQuestionList.length > 0 &&
                    formattedstartDate <= formattedCurrentDate && (
                      <div className="B-G-bold d-gray pull-right mt-10 page-footer default-bg">
                        <Button
                          onClick={(e) => this.saveFinalAnswer(e, "save")}
                          value="Submit"
                          className="me-2"
                        >
                          SUBMIT
                        </Button>
                        <Button
                          onClick={(e) => this.saveFinalAnswer(e, "draft")}
                          value="Save as Draft"
                          className="me-2"
                        // disabled={this.state?.answerList.length === 0}
                        >
                          SAVE AS DRAFT
                        </Button>
                      </div>
                    )}
                  {this.state.showModal && (
                    <Modal className="confirmation-popup"show onHide={this.handleCloseModal}>
                      <Modal.Header closeButton>
                        <Modal.Title className='custom-modal-title question-preview chapter-modal f-14 h5'>
                           Confirmation
                        </Modal.Title>
                      </Modal.Header>
                      <Modal.Body>Are you sure want to submit review form?</Modal.Body>
                      <Modal.Footer className='potential-footer'>
                        <Button
                          variant="primary"   
                          type="submit"
                          onClick={() => this.handleConfirmSave(this.state.saveType)}
                          style={{ backgroundColor: '#f5821f', borderColor: '#f5821f', width: '100px', height: '42px', fontSize: '14px' }}
                        >
                          YES
                        </Button>
                        <Button
                          variant="primary"
                          type="submit"
                          onClick={this.handleCloseModal}
                          style={{ backgroundColor: '#f5821f', borderColor: '#f5821f', width: '100px', height: '42px', fontSize: '14px' }}
                        >
                          NO
                        </Button>
                      </Modal.Footer>
                    </Modal >
                  )}
                </div>
              </div>
            </div>
          )}
        </>
      );
    }
  }
}
const mapStateToProps = (state) => {
  return {
    loginDetails: state.loginDetails,
    pmsQuestionList: state.pmsDetails.pmsQuestion,
    saveAnswerList: state.pmsDetails.saveAnswer,
    addClass: state.pmsDetails.addClass,
    pmsDetails: state.pmsDetails,
    dateList: state.pmsDetails.getStartEndDate,
  };
};
const mapDispatchToProps = {
  getLoginUser,
  getReportFilter,
  getLoginUserPermission,
  getReportFilterPmo,
  getPmsQuestion,
  getStartEndDate,
  saveAnswer,
  setAddClass,
  getAnswer,
};
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(PmsReview)
);