import React, { Component } from "react";
import * as constants from "../../Config/Constant";
import { getBackEndConstant } from "../../Config/Constant";
import Cookies from "js-cookie";
import "./sidebar.css";
import { getLoginUser, getDates, getLoginUserPermission, setAddClass, getNotifications, markReadNotification, getPmsPermission } from "../../Action";
import { connect } from "react-redux";
import SideNav, { Toggle, Nav, NavItem, NavIcon, NavText } from '@trendmicro/react-sidenav';
import '@trendmicro/react-sidenav/dist/react-sidenav.css';
import styled from 'styled-components';
import { Link, withRouter, Redirect } from "react-router-dom";
import PageNotFound from "../Reports/PageNotFound";

const NavHeader = styled.div`
    display: ${props => (props.expanded ? 'block' : 'none')};
    white-space: nowrap;
    background-color: #db3d44;
    color: #fff;

    > * {
        color: inherit;
        background-color: inherit;
    }
`;

// height: 20px + 10px + 10px = 40px
const NavTitle = styled.div`
    font-size: 2em;
    line-height: 20px;
    padding: 10px 0;
`;

// height: 20px + 4px = 24px;
const NavSubTitle = styled.div`
    font-size: 1em;
    line-height: 20px;
    padding-bottom: 4px;
`;

const NavInfoPane = styled.div`
    float: left;
    width: 100%;
    padding: 10px 20px;
    background-color: #eee;
`;

const Separator = styled.div`
    clear: both;
    position: relative;
    margin: .8rem 0;
    background-color: #ddd;
    height: 1px;
`;
class MenuBar extends Component {

    constructor(props) {
        super(props);
        this.state = {
            queryParams: new URLSearchParams(window.location.search),
            isSubMenuOpen: false,
            loginUserData: {},
            loginUserPermission: {},
            loginUserType: '',
            profileImgUrl: '',
            loading: false,
            sidebarExpanded: false,
            selected: '',
            isClicked: false,
            isCollapsed: false,
            dataLoading: false,
            loggedOut: false,
            itemClicked: false,
            redirect: false,
            notificationMenu: false,
            newNotificationCount: 0,
            notificationList: [],
            pmsPermission: false
        };
        this.wrapperRef = React.createRef();
        this.handleClickOutside = this.handleClickOutside.bind(this);
    }

    /**
    * Initial function call
    */
    componentDidMount() {
        let token = this.state.queryParams.get("token") == null ? Cookies.get("auth_data") :
            this.state.queryParams.get("token");
        if (token == 'undefined' || token == undefined || token == null) {
            this.setState({ redirect: 1 });
        }

       let isPathMatch =  window.location.pathname == '/pms_dashboard' || window.location.pathname == '/chapter_review_inference' ?  true : false;
        this.setState({
            token: token,
            apiLink: constants.API_LINK,
            angularUrl: constants.FRONT_LINK,
            excelUrl: constants.EXCEL_LINK,
            isClicked: isPathMatch,
            selected: token == 'undefined' ? 'not-found' : 'pms_dashboard',
        });
        this.getUser();
        document.addEventListener("mousedown", this.handleClickOutside);
    }


    /**
     * Get new notification on interval time basis
     */
     getNewNotification() {
        let postdata = {
            is_new : true
        }

        this.props.getNotifications(postdata)
            .then((response) => {
                this.setState({
                    newNotificationCount: this.props.notificationDetails?.notificationLists?.count,
                    notificationList: this.props.notificationDetails?.notificationLists.lists
                });
            });
    }


    /**
     * Close Menu if clicked on outside of element
     */
    handleClickOutside(event) {
        if (this.wrapperRef && !this.wrapperRef.current?.contains(event.target)) {
            this.setState({ notificationMenu: false });
        }
    }

    /**
     * Show sub menus
     */

    toggleSubMenu = () => {
        this.setState(
            (prevState) => ({
                isSubMenuOpen: !prevState.isSubMenuOpen,
            }),
            () => {
            }
        );
    };

    toggleMenu = () => {
        this.setState(
            (prevState) => ({
                isSubMenuOpen: !prevState.isSubMenuOpen // Toggle the value
            })
        );
    };

    /**
 * Get the login user details
 */
    getUser = () => {
        if (this.props.getLoginUser() != null) {
            this.setState({ loading: true });
            this.props.getLoginUser().then((response) => {
                this.props.getLoginUserPermission().then((response) => {
                    this.setState({
                        loginUserData: this.props.loginDetails.loginDetails,
                        loginUserPermission: this.props.loginDetails.loginUserPermission,


                    });
                    this.setProfileData();
                    if([1, 3, 4, 10, 12].includes(this.props.loginDetails.loginDetails.role_id)){
                        this.getNewNotification();
                        setInterval(() => 
                        this.getNewNotification(), 20000);
                    }
                    this.getPmsPermission();
                });               
            });
        }
    }
        /**
     * Get the accessable user list
     */
    getPmsPermission = () => {
        this.props.getPmsPermission()
        .then((response) =>{
            if(this.props.getPmsPermissionData?.result){
                let checkUser = this.props.getPmsPermissionData.result;
                console.log(checkUser);
                checkUser = checkUser.some(users => users.id == this.state.loginUserData.id);
                console.log(this.state.loginUserData.id);
                if(checkUser){
                    this.setState({
                        loading: false,
                        pmsPermission: true
                    });                   
                } else {
                    this.setState({
                        loading: false,                        
                    });
                }
            }
        });
    }
    /**
     * Set profile image
     */
    setProfileData = () => {

        switch (this.state.loginUserData.role_id) {

            case 1://super admin
                this.setState({
                    loginUserType: 'Super Admin',
                    profileImgUrl: getBackEndConstant().imageUrl + '/images/user_role_img/admin.png'
                });
                break;
            case 3://BA
                this.setState({
                    loginUserType: 'Business Analyst',
                    profileImgUrl: getBackEndConstant().imageUrl + 'images/user_role_img/businessAnalysit.png'
                });
                break;
            case 4://'TL'
                this.setState({
                    loginUserType: 'Team Lead',
                    profileImgUrl: getBackEndConstant().imageUrl + 'images/user_role_img/teamlead.png'
                });
                break;
            case 5://'Developer'
                this.setState({
                    loginUserType: 'Developer',
                    profileImgUrl: getBackEndConstant().imageUrl + 'images/user_role_img/developer.png'
                });
                break;

            case 6://'UIX'
                this.setState({
                    loginUserType: 'UIX Designer',
                    profileImgUrl: getBackEndConstant().imageUrl + 'images/user_role_img/designer.png'
                });
                break;
            case 7://'QA'
                this.setState({
                    loginUserType: 'Tester',
                    profileImgUrl: getBackEndConstant().imageUrl + 'images/user_role_img/tester.png'
                });
                break;
            case 8://'Director'
                this.setState({
                    loginUserType: 'Director',
                    profileImgUrl: getBackEndConstant().imageUrl + 'images/user_role_img/director.png'
                });
                break;
            case 11://'PMO - MIS'
                this.setState({
                    loginUserType: 'PMO - MIS',
                    profileImgUrl: getBackEndConstant().imageUrl + 'images/user_role_img/pmo.png'
                });
                break;
            case 10://'Practice Manager'
                this.setState({
                    loginUserType: 'Practice Manager',
                    profileImgUrl: getBackEndConstant().imageUrl + 'images/user_role_img/teamlead.png'
                });
                break;
            case 12://'Finance Team'
                this.setState({
                    loginUserType: 'Finance Team',
                    profileImgUrl: getBackEndConstant().imageUrl + 'images/user_role_img/financer.png'
                });
                break;
            default:
                this.setState({
                    loginUserType: (this.state.loginUserPermission.user_odoo_role ? this.state.loginUserPermission.user_odoo_role.name : 'Users'),
                    profileImgUrl: getBackEndConstant().imageUrl + 'images/user_role_img/sales.png'
                });
                break;
        }
    };

    onSelect = (selected) => {
        this.setState({ selected: selected });
        this.props.history.push(`/${selected}`)
    };
    onToggle = (expanded) => {
        this.props.setAddClass(!this.props.addClass);
        // this.setState({ expanded: expanded });
        this.setState((prevState) => ({
            isCollapsed: !prevState.isCollapsed,
            expanded: expanded
        }));
    };

    handleClick = () => {
        // Toggle the isClicked state when the <i> element is clicked
        this.setState((prevState) => ({
            isClicked: !prevState.isClicked,
        }));
    };

    toggleNotificationMenu = () => {
        this.setState({ notificationMenu: !this.state.notificationMenu });
    }

    viewAll = () => {
        if(window.location.pathname == '/notifications'){
            window.location.reload();
        }
        this.toggleNotificationMenu();
    }

    logout = () => {
        this.setState({ dataLoading: true });
        // Simulate an API call for logout
        setTimeout(() => {
            // In a real application, you would make an API request here
            // For the sake of the example, we'll use a setTimeout
            const response = { status: true, message: 'Logged out successfully' };
            if (response.status) {
                // Clear user credentials and set the loggedOut state to true
                // This will redirect the user to the login page
                this.setState({ dataLoading: false, loggedOut: true });
            } else {
                // Handle error, e.g., show an error message
                this.setState({ dataLoading: false });
                alert('Error: ' + response.message);
            }
        }, 1000); // Simulated API call delay (1 second)
    };

    getNotificationItem = (event,list) =>{
        event.preventDefault();
        let notificationRemove = this.state.notificationList
                                    .filter((nlist) => nlist.id != list.id);
        this.setState({ notificationList: notificationRemove, newNotificationCount: this.state.newNotificationCount - 1,notificationMenu: false });

        let postdata = {
           id: list.id
        }
        this.props.markReadNotification(postdata).then((response) => {
            console.log(this.props.notificationDetails.markNotification);
        });

        const path = '/' + list.redirect_uri;
        this.props.history.push({
            pathname: path,
            state: {
                filter_name: list.filter_name,
                filter_id: list.module_id,
            }
        });
    }

    render() {
        if (this.state.redirect == 1) {
            return <PageNotFound url={this.state.angularUrl} />;
        }
        const { loginUserType, profileImgUrl } = this.state;
        const currentPath = window.location.pathname;
        const { expanded, selected } = this.state;
        const navWidthCollapsed = 70;
        const navWidthExpanded = 200;
        return (
            <>
                {this.state.loading && (
                    <div id="preloader">
                        <div id="status"></div>
                    </div>
                )}
                <body>
                    <header className="l-header">
                        <div className="l-header__inner clearfix">
                            <div className="header-icons-group">
                                {(constants.notificationRoles.includes(this.state.loginUserData.role_id) && !['/chapter_review_inference','/chapter_review_details'].includes(currentPath)) &&
                                    <>
                                    <div ref={this.wrapperRef} className="dropdown notification-dropdown" style={{ width: "32px"}}>
                                        <a href="javascript:void(0);" role="button" data-toggle="dropdown" id="notification-dropdown" data-target="#" aria-expanded="true" onClick={this.toggleNotificationMenu}>
                                            <i className="fa fa-bell-o" style={{fontSize: "18px", color: "#7d7d7d"}}>
                                            </i>
                                        </a>
                                        {this.state.newNotificationCount > 0 && <i className="badge badge-danger">{ this.state.newNotificationCount <= 10 ? this.state.newNotificationCount : '10+' }</i>}
                                        <ul className={`dropdown-menu ${this.state.notificationMenu ? 'show' : ''}`} role="menu" aria-labelledby="notification-dropdown">
                                            <li role="presentation">
                                                <h6>Notifications 
                                                    {this.state.newNotificationCount > 0 && 
                                                        <Link to={'/notifications'} onClick={this.viewAll}>
                                                            <span className="btn btn-primary B-G-bold xs-block-view pull-right">
                                                                View All</span>
                                                        </Link>
                                                    }
                                                </h6>
                                            </li>
                                            <ul className="timeline timeline-icons timeline-sm">
                                                { this.state.newNotificationCount == 0 &&
                                                    <li>
                                                        <div className="text text-center text-secondary py-3">
                                                                No new notifications
                                                        </div>
                                                    </li>
                                                }
                                                { this.state.newNotificationCount > 0 && this.state.notificationList.map((lists) => {
                                                    return (
                                                        <li>
                                                            <div className="notifications-item" onClick={(e) => this.getNotificationItem(e,lists)}> 
                                                                <span><i className="fa fa-user"></i></span>
                                                                <div className="text">
                                                                    <h5>{ lists.get_user?.first_name +' '+ (lists.get_user?.last_name || '')}</h5>
                                                                    <p className="text-wrap">{ lists?.text }</p>
                                                                </div>
                                                            </div>
                                                        </li>
                                                    )
                                                })
                                                }
                                            </ul>
                                        </ul>
                                    </div>
                                    </>
                                }
                                <div className="dropdown">
                                    <span className="icon-user"></span>
                                    <span className="hidden-xs l-gray B-G-bold f-s-16">Welcome, <b className="d-gray">{this.state.loginUserData.first_name} {this.state.loginUserData.last_name}</b></span>
                                </div>
                            </div>
                        </div>
                    </header>
                    <SideNav
                        style={{ minWidth: !this.state.isCollapsed ? navWidthExpanded : navWidthCollapsed }}
                        onSelect={this.onSelect}
                        onToggle={this.onToggle}
                        expanded={!this.state.isCollapsed}
                    >
                        <div className="top-data">
                            <div className="logo m-t-15">
                                <div className="">
                                    <img src={getBackEndConstant().imageUrl + "/styles/images/logo-after-login.png"} className="l-logo" alt="" />
                                    <img src={getBackEndConstant().imageUrl + "/styles/images/r-logo.png"} className="r-logo" alt="" />
                                </div>
                            </div>
                        </div>
                        <Toggle />

                        <Nav defaultSelected={selected} className="sideClass">
                            <Separator />
                            <div className="profile-pic text-center">
                                <img src={profileImgUrl} alt="Profile" className="profileImage" />
                                <span className="clr dis-b c-orange mt-10 B-G-black f-s-14 t-t-upper">{loginUserType}</span>
                            </div>
                            <Separator />
                            {(this.state.loginUserData.role_id != 5 && (constants.reportURLS.some((path) => currentPath.includes(path)) || currentPath == '/notifications')) &&
                                <NavItem eventKey="pw_report" onClick={this.handleClick} >
                                    <NavIcon >
                                        <i className={"fa fa-fw fa-list-alt "+ (constants.reportURLS.some((path) => currentPath.includes(path)) ? "active" : "")} style={{ fontSize: '20px', verticalAlign: 'middle' }} />
                                    </NavIcon>
                                    <NavText title="REPORTS">
                                        <Link className={"nav-link " + (constants.reportURLS.some((path) => currentPath.includes(path)) ? "active" : "")} to={"/pw_report"} onClick={this.toggleMenu}>
                                            <span className={"menu-text " + (constants.reportURLS.some((path) => currentPath.includes(path)) ? "active" : "")}>Reports</span></Link>

                                    </NavText>
                                </NavItem>
                            }
                            { (constants.PMSURLS.some((path) => currentPath.includes(path)) || currentPath == '/notifications' || currentPath == '/pms_guide') &&
                                <NavItem eventKey="pms" expanded={this.state.isClicked} className={(this.state.isClicked ? "active " : "")}>
                                    <NavIcon onClick={this.handleClick}>
                                        <i className="icon-list" style={{ fontSize: '20px' }} />
                                    </NavIcon>
                                    <NavText title="PMS" onClick={this.handleClick}>
                                        PMS
                                    </NavText>
                                    <NavItem eventKey="pms_dashboard">
                                        <NavIcon>
                                            <i className={"icon-home submenu-text " + (currentPath == '/pms_dashboard' ? "active" : "")} style={{ fontSize: '20px' }} onClick={this.handleClick} />
                                        </NavIcon>
                                        <NavText title="Dashboard">
                                            <Link to={'/pms_dashboard'} >
                                                <span className={"submenu-text " + (currentPath == '/pms_dashboard' ? "active" : "")} >
                                                    Dashboard</span>
                                            </Link>
                                        </NavText>
                                    </NavItem>
                                    {(this.state.loginUserData.role_id !== 8 && this.state.loginUserData.role_id !== 1) &&
                                        <NavItem eventKey="pms_review">
                                            <NavIcon>
                                                <i className={"fa fa-star submenu-text " + (currentPath == '/pms_review' ? "active" : "")} style={{ fontSize: '20px' }} onClick={this.handleClick} />
                                            </NavIcon>
                                            <NavText title="Review">
                                                <Link to={'/pms_review'}  >
                                                    <span className={"submenu-text " + (currentPath == '/pms_review' ? "active" : "")} >
                                                        Performance Review</span>
                                                </Link>
                                            </NavText>
                                        </NavItem>
                                    }
                                    {(this.state.loginUserData.role_id === 4 || this.state.loginUserData.role_id === 1 || this.state.loginUserData.role_id === 8 ||
                                        this.state.loginUserData.role_id === 10 || this.state.pmsPermission) &&
                                        <NavItem eventKey="pms_manage">

                                            <NavIcon className={"submenuIcon " + (expanded ? "active" : "")}>
                                                <i className={"fa fa-question-circle submenu-text " + (currentPath == '/pms_manage' ? "active" : "")} style={{ fontSize: '20px' }} onClick={this.handleClick} />
                                            </NavIcon>
                                            <NavText title="Manage Questions">
                                                <Link to={'/pms_manage'}  >
                                                    <span className={"submenu-text " + (currentPath == '/pms_manage' ? "active" : "")} >
                                                        Manage Questions</span>
                                                </Link>
                                            </NavText>
                                        </NavItem>
                                    }
                                    {(this.state.loginUserData.role_id == 1 || this.state.loginUserData.role_id == 8 || this.state.pmsPermission) &&
                                        <NavItem eventKey="pms_settings">
                                            <NavIcon>
                                                <i className={"fa fa-gear submenu-text " + (currentPath == '/pms_settings' ? "active" : "")} style={{ fontSize: '20px' }} onClick={this.handleClick} />
                                            </NavIcon>
                                            <NavText title="Settings">
                                                <Link to={'/pms_settings'} >
                                                    <span className={"submenu-text " + (currentPath == '/pms_settings' ? "active" : "")} >
                                                        Settings</span>
                                                </Link>
                                            </NavText>
                                        </NavItem>
                                    }                                     
                                </NavItem>
                            }
                            {(['/chapter_review_inference','/chapter_review_details'].includes(currentPath)) &&
                                <NavItem eventKey="chapter_review" expanded={this.state.isClicked} className={(this.state.isClicked ? "active " : "")}>
                                    <NavIcon onClick={this.handleClick}>
                                        <i className="icon-note" style={{ fontSize: '20px' }} />
                                    </NavIcon>
                                    <NavText title="Chapter Review" onClick={this.handleClick}>
                                        Chapter Review
                                    </NavText>
                                    <NavItem eventKey="chapter_review_inference">
                                        <NavIcon>
                                            <i className={"icon-bubble submenu-text " + (currentPath == '/chapter_review_inference' ? "active" : "")} style={{ fontSize: '20px', verticalAlign: 'middle' }}  onClick={this.handleClick} />
                                        </NavIcon>
                                        <NavText title="Inference">
                                            <Link to={'/chapter_review_inference'} >
                                                <span className={"submenu-text " + (currentPath == '/chapter_review_inference' ? "active" : "")} >
                                                    Inference</span>
                                            </Link>
                                        </NavText>
                                    </NavItem>
                                    <NavItem eventKey="chapter_review_details">
                                        <NavIcon>
                                            <i className={"icon-info submenu-text " + (currentPath == '/chapter_review_details' ? "active" : "")} style={{ fontSize: '20px', verticalAlign: 'middle' }}  onClick={this.handleClick} />
                                        </NavIcon>
                                        <NavText title="Chapter Details">
                                            <Link to={'/chapter_review_details'} >
                                                <span className={"submenu-text " + (currentPath == '/chapter_review_details' ? "active" : "")} >
                                                    Chapter Details</span>
                                            </Link>
                                        </NavText>
                                    </NavItem>
                                </NavItem>
                            }
                            {(constants.notificationRoles.includes(this.state.loginUserData.role_id) && !['/chapter_review_inference','/chapter_review_details'].includes(currentPath)) &&
                                <NavItem eventKey="notifications">
                                    <NavIcon onClick={this.handleClick} >
                                        <i className={"fa fa-bell-o " + (currentPath == '/notifications' ? "active" : "")} style={{ fontSize: '20px', verticalAlign: 'middle' }} />
                                        {this.state.newNotificationCount > 0 && <i className="badge badge-warning badge-collapse">{ this.state.newNotificationCount <= 10 ? this.state.newNotificationCount : '10+' }</i>}
                                    </NavIcon>
                                    <NavText title="NOTIFICATIONS">
                                        <Link className={"nav-link " + (currentPath == '/notifications' ? "active" : "")} to={"/notifications"} onClick={this.toggleMenu}>
                                            <span className={"menu-text " + (currentPath == '/notifications' ? "active" : "")}>Notifications
                                            {this.state.newNotificationCount > 0 && <i className="badge badge-warning">{ this.state.newNotificationCount <= 10 ? this.state.newNotificationCount : '10+' }</i>}
                                            </span></Link>
                                    </NavText>
                                </NavItem>
                            }
                            { (constants.PMSURLS.some((path) => currentPath.includes(path)) || currentPath == '/notifications' || currentPath == '/pms_guide') && 
                                <NavItem eventKey="pms_guide">
                                    <NavIcon onClick={this.handleClick} >
                                        <i className={"fa fa-file-video-o " + (currentPath == '/pms_guide' ? "active" : "")} style={{ fontSize: '20px', verticalAlign: 'middle' }} />
                                    </NavIcon>
                                    <NavText title="GUIDE">
                                        <Link className={"nav-link " + (currentPath == '/pms_guide' ? "active" : "")} to={"/pms_guide"} onClick={this.toggleMenu}>
                                            <span className={"menu-text " + (currentPath == '/pms_guide' ? "active" : "")}>Guide
                                            </span></Link>
                                    </NavText>
                                </NavItem>
                            }
                        </Nav>
                    </SideNav>
                </body>
            </>
        )
    }
}

const mapStateToProps = state => {
    return {
        loginDetails: state.loginDetails,
        addClass: state.pmsDetails.addClass,
        notificationDetails: state.notificationDetails,
        getPmsPermissionData: state.pmsDetails.getPmsPermissionData
    };
};
const mapDispatchToProps = {
    getLoginUser,
    getLoginUserPermission,
    getDates,
    setAddClass,
    getNotifications,
    markReadNotification,
    getPmsPermission
};

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(MenuBar));