import React, { Component } from "react";
import {
  getDates,
  getPmsDashboard,
  getReportFilter,
  getLoginUserPermission,
  getReportFilterPmo,
  setAddClass,
  downloadStatistics,
  getPmsPermission,
} from "../../Action";
import { connect } from "react-redux";
import * as constants from "../../Config/Constant";
import PageNotFound from "../Reports/PageNotFound";
import { Row, Col, Button } from "react-bootstrap";
import Swal from "sweetalert2";
import Select from "react-select";
import accessdenied from "../../access-denied.png";
import Cookies from "js-cookie";
import { Link } from "react-router-dom";
import PmsSettingGrid from "./PmsSettingGrid";
import AddUpdateSettings from "./AddUpdateSettings";
import PmsPermission from "./PmsPermission";

const Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  showCloseButton: true,
  customClass: {
    popup: "colored-toast",
    icon: "white-toast-icon",
  },
  timer: 3000,
  onOpen: (toast) => {
    toast.addEventListener("mouseenter", Swal.stopTimer);
    toast.addEventListener("mouseleave", Swal.resumeTimer);
  },
});

class PmsSettings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      queryParams: new URLSearchParams(window.location.search),
      redirect: 0,
      sname: [],
      loginUserData: {},
      loginUserPermission: {},
      loading: false,

      role_id: 0,
      isActionsPage: false,
      performanceDateYear: {},
      performanceDate: "",
      dashboardLoader: false,
      showSettingModal: false,
      pmsPermission: false
    };
  }

  componentDidMount() {
    let token =
      this.state.queryParams.get("token") === null
        ? Cookies.get("auth_data")
        : this.state.queryParams.get("token");
    let url =
      this.state.queryParams.get("url") == null
        ? Cookies.get("url_code")
        : this.state.queryParams.get("url");
    this.setState({
      token: token,
      apiLink: constants.API_LINK,
      angularUrl: constants.FRONT_LINK,
      excelUrl: constants.EXCEL_LINK,
      url: url,
    });
    Cookies.set("auth_data", token);
    Cookies.set("url_code", url);
    this.state.queryParams.delete("token");
    this.state.queryParams.delete("url");
    if (this.props.history != undefined) {
      this.props.history.replace({
        search: this.state.queryParams.toString(),
      });
    }
    if (token == "undefined" || token == undefined || token == null) {
      this.state.redirect = 1;
    } else {
      this.getUser();
    }
  }

  componentDidUpdate(prevProps) {
    if (
      JSON.stringify(this.props.loginDetails) !==
      JSON.stringify(prevProps.loginDetails)
    ) {
      this.getUser();
    }
  }
  /**
   * Get the login user details
   */
  getDates = (selectedDate) => {
    const payload = {
      data: {
        performanceDate: selectedDate.split('-')[0],
      },
    };
    this.props.getDates(payload).then((response) => {
      this.setState({ dashboardLoader: false });
    });
  };

  getUser = () => {
    const role_id = this.props?.loginDetails?.loginDetails?.role_id;
    this.setState({
      loginUserData: this.props?.loginDetails?.loginDetails,
      loginUserPermission: this.props?.loginDetails?.loginUserPermission,
      role_id,
      dashboardLoader: true,
    });

    if (role_id != null || role_id != undefined || role_id != "undefined") {
      this.getPerformanceDate();
      this.setState({ dashboardLoader: false });
    }
    this.getPmsPermission();
  };
  getPmsPermission = () => {
    this.props.getPmsPermission()
    .then((response) =>{
        if(this.props.getPmsPermissionData?.result){
            let checkUser = this.props.getPmsPermissionData.result;
            checkUser = checkUser.some(users => users.id == this.state.loginUserData.id);
            //console.log(this.state.loginUserData.id);
            if(checkUser){
                this.setState({
                    loading: false,
                    pmsPermission: true
                });                   
            } else {
                this.setState({
                    loading: false,                        
                });
            }
        }
    });
  }
  /* toast message function  */
  ToastAlert(icon, title) {
    return Toast.fire({
      target: document.getElementById("form-modal"),
      icon: `${icon}`,
      title: `${title}`,
    });
  }

  getPerformanceDateYearOption = () => {
    const currentYear = new Date().getFullYear();
    return [
      {
        id: 0,
        label: currentYear,
        value: currentYear,
      }
    ];
  };
  
  performanceDateYearhandle = (e) => {
    this.setState({
      performanceDateYear: e,
      performanceDate: `${e.label}-10-01`,
    });
    this.getDates(`${e.label}-10-01`);
  };

  handlePerformanceDate = (date) => {
    this.setState({
      performanceDate: date,
    });
    this.getDates(date);
  };

  getPerformanceDate = () => {
    const currentMonth = new Date().getMonth();
    const currentYear = new Date().getFullYear();
    const performanceMonth = "04";

    this.setState(
      {
        performanceDateYear: {
          label: currentYear,
          value: currentYear,
        },
        performanceDate: `${currentYear}-${performanceMonth}-01`,
      },
      () => {
        this.getDates(this.state.performanceDate);
      }
    );
  };

  addSettings = () => {
    this.setState({ showSettingModal: true });
  };

  handleCloseModal = () => {
    this.setState({
      showSettingModal: false,
      startDate: new Date(),
      endDate: new Date(),
      batch: "April",
      month: "0",
    });
    this.props.getDates({
      data: {
        performanceDate: this.state.performanceDateYear.label,
      },
    });
  };

  render() {
    const {
      isActionsPage,
      redirect,
      dashboardLoader,
      loginUserData,
      performanceDate,
      performanceDateYear,
      role_id,
    } = this.state;

    if (redirect === 1 || loginUserData === null) {
      return <PageNotFound url={constants.FRONT_LINK} />;
    } else if (isActionsPage) {
      return <></>;
    } else {
      return (
        <>
        <div
          className={`page-content px-4 pms-dashboard-container ${this.props.addClass ? "active" : ""
            }`}
        >
          <Row>
            <div className="col-xs-12 fourty-fluid fourty-fluid">
              <Row className="row">
                <div className="col-sm-4 col-sm-4 col-xs-12 col-md-12 text-left">
                  <h1 className="B-G-bold f-s-18 d-gray t-t-upper pull-left mt-15 mr-20 page-head lh-10">
                    PMS-SETTING
                  </h1>
                </div>
              </Row>

              <Row className="service-container px-2">
                <Col className="text-left bgwhite mr-10">
                  {(role_id === 8 ||
                    role_id === 10 ||
                    role_id === 1 ||
                    role_id === 4 ||
                    role_id === constants.HR) && (
                      <div className="d-flex align-items-baseline performance-year">
                        <label className="style-label fs-12 m-r-20">
                          Select Year
                        </label>
                        <div className="selectpicker_dropdown hide_bar selectpicker_dropdown_orange">
                          <Select
                            className="basic-single-select"
                            classNamePrefix={"select_dropdown"}
                            onChange={(e) => this.performanceDateYearhandle(e)}
                            value={performanceDateYear}
                            options={this.getPerformanceDateYearOption()}
                          ></Select>
                        </div>
                      </div>
                    )}
                  <div className="col-sm-4 col-sm-4 col-xs-12 col-md-12 text-left">
                    <Col>
                      <div className="project-list text-left custom-li-size d-flex" style={{ justifyContent: 'space-between' }}>
                        {(role_id === 8 ||
                          role_id === 10 ||
                          role_id === 1 ||
                          role_id === 4 ||
                          role_id === constants.HR) && (
                            <ul className="p-l-0 d-flex text-center w-100 mb-3" style={{ justifyContent: 'space-between' }}>
                              {/* <li
                                className={`B-G-bold f-s-12 px-3 mr-5 py-2 c-pointer ${`${performanceDateYear.value}-04-01` ===
                                  performanceDate
                                  ? "pr-l-active"
                                  : "pr-l-gray"
                                  }`}
                                onClick={() =>
                                  this.handlePerformanceDate(
                                    `${performanceDateYear.value}-04-01`
                                  )
                                }
                              >
                                 April Batch {" "}
                                {performanceDateYear.value}
                              </li>
                              <li
                                className={`B-G-bold f-s-12 px-3 mr-5 py-2 c-pointer ${`${performanceDateYear.value}-10-01` ===
                                  performanceDate
                                  ? "pr-l-active"
                                  : "pr-l-gray"
                                  }`}
                                onClick={() =>
                                  this.handlePerformanceDate(
                                    `${performanceDateYear.value}-10-01`
                                  )
                                }
                              >
                                 October Batch {" "}
                                {performanceDateYear.value}
                              </li> */}
                              <Button
                                className="me-2 ms-auto"
                                onClick={(e) => this.addSettings(e)}
                              >
                                Add Settings
                              </Button>
                            </ul>
                          )}
                      </div>
                      <div></div>
                    </Col>
                  </div>
                </Col>
              </Row>
              <hr className="m-t-5 col-sm-12 m-b-8 p-0"></hr>
              {(role_id === 8 ||
                role_id === 10 ||
                role_id === 1 ||
                role_id === 4 ||
                this.state.pmsPermission ||
                role_id === constants.HR) && (
                  <div>
                    {dashboardLoader ? (
                      <div id="preloader">
                        <div id="status"></div>
                      </div>
                    ) : (
                      <PmsSettingGrid
                        dataList={this.props.pmsDetails.settingDates}
                        performanceDate = {this.state.performanceDate}
                        performanceDateYear = {this.state.performanceDateYear}
                        isEditFlag={
                          this.props.pmsDetails.settingDates.id ? true : false
                        }
                      />
                    )}
                  </div>
                )}
            </div>
          </Row>
          <>
            <AddUpdateSettings
              settingsData={this.state}
              isEditFlag={false}
              handleCloseModal={this.handleCloseModal}
            />
          </>
        </div>        
        <PmsPermission></PmsPermission>
        </>
      );
    }
  }
}
const mapStateToProps = (state) => {
  return {
    loginDetails: state.loginDetails,
    pmsDetails: state.pmsDetails,
    getDates: state.loginDetails.settingDates,
    getPmsPermissionData: state.pmsDetails.getPmsPermissionData
  };
};

const mapDispatchToProps = {
  getPmsDashboard,
  getReportFilter,
  getLoginUserPermission,
  getReportFilterPmo,
  setAddClass,
  downloadStatistics,
  getPmsPermission,
  getDates,
};

export default connect(mapStateToProps, mapDispatchToProps)(PmsSettings);